<template>
  <div
    :class="`layout ${isMobile ? 'mobile': 'desktop'} ${hasTouch ? 'touch-device': 'not-touch-device'} ${isCtrlKeyDown ? 'ctrl-key-down' : ''} ${menuHidden ? 'menu-hidden' : ''} ${editingView ? 'editing-view': ''}`"
  >
    <portal-target name="dropdownContent" />
    <div class="layout-wrapper">
      <section v-if="appLoaded" class="main-content">
        <div v-if="isMobile || shouldHideSidebar()" class="view-title">
          <a class="hamburger-menu" @click="$refs.leftDrawer.open()">
            <i class="mdi mdi-menu" />
          </a>
        </div>
        <Drawer v-if="isMobile || shouldHideSidebar()" ref="leftDrawer" :exist="true" direction="left" class="no-print">
          <Sidebar />
        </Drawer>
        <Sidebar v-else class="sidebar no-print" />
        <div class="main-column" :style="statusMessage ? 'top: 40px': ''">
          <Toolbar v-if="!isMobile" class="toolbar no-print" :style="statusMessage ? 'margin-top: 40px': ''"/>
          <ErrorManager v-if="scriptErrorReportAdress" />
          <portal-target name="blockEditorModals" />
          <div v-if="showPanel && panels && config" :key="$route.path" class="content">
            <nuxt :key="currentWorkgroup" />
          </div>
        </div>
        <portal-target name="layout-right" class="layout-right"/>
        <StatusBar class="statusbar no-print" />
        <EditModal
          :edited-model="addedPanel"
          :title="i18n.add_panel"
          :config="panelsConfig"
          :show-history="false"
          @save="addPanel"
          @close="addedPanel = undefined"
        />
        <EditModal
          :edited-model="editedPanel"
          :show-delete-button="true"
          :title="i18n.edit_panel"
          :config="panelsConfig"
          fetch-object-on-backend
          @onDelete="deletePanel"
          @save="editPanel"
          @close="editedPanel = undefined"
        />
        <EditModal
          :edited-model="addedElement"
          ref="addElementModal"
          :config="addedElementPanel"
          :title="i18n.add_element"
          :show-history="false"
          @save="addElement($event, addedElementPanel)"
          @close="$store.commit('panels/setAddedElement', undefined)"
        />
        <!--
        <EditModal
          ref="editedElementEditModal"
          :edited-model="editedElement"
          :show-delete-button="true"
          :title="i18n.edit_element"
          :config="editedElementPanel"
          @onDelete="deleteElement"
          @save="editElement($event, editedElementPanel)"
          @close="$store.commit('panels/setEditedElement', undefined)"
        />
        -->
        <portal-target name="editModal" />
        <portal-target name="paneltitlebar-modals" />
        <portal-target name="addon-modal" />
        <portal-target name="user-buttons-modals" />
        <TemplatePanelCreator :active="showPanelFromTemplateModal" @close="closeTemplatePanelCreator" />
      </section>
    </div>
    <div v-if="!editingView" class="main-action-button">
      <portal-target name="main-action-button" />
    </div>
    <FilePreview />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import '@mdi/font/css/materialdesignicons.css';
import { isMobile } from 'mobile-device-detect';

import StatusBar from '@/components/StatusBar';
// import SyncBar from '@/components/SyncBar';
import Drawer from '@/components/Drawer';
import ErrorManager from '@/components/ErrorManager';
import Toolbar from '@/components/Toolbar';
import actions from '@/permissions/actions';
import PermissionManager from '@/permissions/PermissionManager';
import { hasTouch } from 'detect-touch';
import i18n from 'i18n/layouts/default.json';
import { ToastProgrammatic as Toast } from 'buefy';
import * as Sentry from "@sentry/browser";
import redirect from '@/core/utils/redirect';

import '@/core/defaultComponents';

import Vue from 'vue';
import VueFormGenerator from 'vue-form-generator';

import publishErrorReport from '@/core/utils/publishErrorReport';

Vue.component('vue-form-generator', VueFormGenerator.component);
// import Gantt from '../../../gantt/src/components/Gantt.vue';
// Vue.component('gantt', Gantt);
window.getDateFromWeek = (str) => {
  const splitted = str.split('-');
  const year = parseInt(splitted[0]);
  const weekNumber = parseInt(splitted[1]);
  return new Date(year, 0, 1 + ((weekNumber - 1) * 7));
};

export default {
  name: 'LayoutDefault',
  components: {
    ErrorManager,
    Toolbar,
    Drawer,
    EditModal: () => import(/* webpackChunkName: "EditModal" */ '@/components/modals/EditModal'),
    FilePreview: () => import(/* webpackChunkName: "EditModal" */ '@/components/FilePreview'),
    Sidebar: () => import(/* webpackChunkName: "Sidebar" */ '@/components/Sidebar'),
    StatusBar,
    // SyncBar,
    TemplatePanelCreator: () => import(/* webpackChunkName: "TemplatePanelCreator" */ '@/components/TemplatePanelCreator'),
  },
  data() {
    return {
      i18n,
      actions,
      PermissionManager,
      appLoaded: false,
      showPanel: true,
      isMobile,
      hasTouch,
      onCtrlKeyUpListener: undefined,
      onCtrlKeyDownListener: undefined,
      isCtrlKeyDown: false,
      syncing: false,
      scriptErrorReportAdress: process.env.VUE_APP_SCRIPT_ERROR_REPORT_ADRESS,
    };
  },
  computed: {
    ...mapState({
      statusMessage: (state) => state.statusMessages.statusMessage,
      config: (state) => state.config.config,
      menuHidden: (state) => state.panels.editingView || state.config.menuHidden,
      editingView: (state) => state.panels.editingView,
      abstractElementsObjects: (state) => state.abstractElements.objects,
      user: (state) => state.login.user,
      currentWorkgroup: (state) => state.login.currentWorkgroup,
      addedElement: (state) => state.panels.addedElement,
      // editedElement: state => state.panels.editedElement,
      addedElementPanel: (state) => state.panels.addedElementPanel,
      editedElementPanel: (state) => state.panels.editedElementPanel,
      seeAsUser: (state) => state.login.seeAsUser,
    }),
    panels: {
      get() { return this.$store.state.abstractElements.objects.panels; },
    },
    showPanelFromTemplateModal: {
      get() { return this.$store.state.config.showPanelFromTemplateModal; },
      set(v) {
        this.$store.commit('config/setShowPanelFromTemplateModal', v);
        if (!v) {
          // this.$store.commit('panels/setEditingView', v);
        }
      },
    },
    onLine: {
      get() { return this.$store.state.offlineHandler.online; },
      set(v) { this.$store.commit('offlineHandler/setOnline', v); },
    },
    addedPanel: {
      get() { return this.$store.state.panels.addedPanel; },
      set(v) { this.$store.commit('panels/setAddedPanel', v); },
    },
    editedPanel: {
      get() { return this.$store.state.panels.editedPanel; },
      set(v) {
        this.$store.commit('panels/setEditingView', v !== undefined);
      },
    },
    panelsConfig() {
      const res = {};
      if (!this.appLoaded) {
        return {};
      }

      if (!this.$store.state.abstractElements.objects.panels || !this.$store.state.abstractElements.objects.panels.objects) {
        return {};
      }

      const panels = this.$store.state.abstractElements.objects.panels.objects;


      for (let i = 0; i < panels.length; i++) {
        if (panels[i].collection === 'panels') {
          return panels[i];
        }
      }
      return res;
    },
  },
  watch: {
    currentWorkgroup: {
      handler: 'loadBaseConfig',
      immediate: true,
    },
    seeAsUser: 'loadBaseConfig',
    $route() {
      if (this.$store.state.config.selection && this.$store.state.config.selection.length) {
        this.$store.commit('config/setSelection', []);
      }
      if (this.$refs.leftDrawer) {
        this.$refs.leftDrawer.close();
      }
    },
  },
  mounted() {
    this.onCtrlKeyDownListener = document.addEventListener('keydown', (e) => {
      if (e.key === 'Control') {
        this.isCtrlKeyDown = true;
      }
    });
    this.onCtrlKeyUpListener = document.addEventListener('keyup', (e) => {
      if (e.key === 'Control') {
        this.isCtrlKeyDown = false;
      }
    });
  },
  methods: {
    /**
     * fetch records that are linked to transfersal features of the app (configuration, favorites, schemas, panels ...).
     * This method is called when the workgroup is changed, as fetched records are dependant of the workgroup.
     */
    async loadBaseConfig() {
      console.log('loadBaseConfig');
      await this.$store.dispatch('config/fetchConfig');
      if (this.$route.query.workgroup) {
        this.$store.commit('login/changeWorkgroup', this.$route.query.workgroup);
      }
      const { config } = this.$store.state.config;
      const root = document.documentElement;
      if (config.primaryColor) {
        root.style.setProperty('--primary-color', config.primaryColor);
      }
      if (config.primaryColorDarker) {
        root.style.setProperty('--primary-color-darker', config.primaryColorDarker);
      }
      if (config.primaryColorLighter) {
        root.style.setProperty('--primary-color-lighter', config.primaryColorLighter);
      }
      if (this.user) {
        if(process.env.VUE_APP_SENTRY_DSN && process.env.VUE_APP_SENTRY_ORIGIN) {
          Sentry.setUser({ username: this.user.username });
        }
        const favFetch = this.$store.dispatch('favorites/fetch');
        const panelsFetch = this.$store.dispatch('abstractElements/fetchObjects', { collection: 'panels' });
        const schemasFetch = this.$store.dispatch('abstractElements/fetchObjects', { collection: 'schemas' });
        const dashboardsFetch = this.$store.dispatch('abstractElements/fetchObjects', { collection: 'system_dashboards' });
        const pagesFetch = this.$store.dispatch('abstractElements/fetchObjects', { collection: 'system_pages' });
        await favFetch;
        await panelsFetch;
        await schemasFetch;
        await dashboardsFetch;
        await pagesFetch;
      }

      if(this.$router.currentRoute.fullPath.startsWith('/panel')) {
        const currentPanelId = this.$router.currentRoute.params.id;
        if (currentPanelId) {
          console.log('this.abstractElementsObjects', this.abstractElementsObjects);
          const panels = this.abstractElementsObjects.panels.objects;
          const currentPanel = panels.find((p) => p._id === currentPanelId);
          if (currentPanelId && !currentPanel) {
            redirect(this.$router, ['/']);
          }
        }
      }
      console.log('loadBaseConfig done');
      this.appLoaded = true;
    },
    shouldHideSidebar() {
      let schema;
      if (this.$route.params.collection) {
        schema = this.getSchemaByName(this.$route.params.collection);
      }
      return (this.$router.currentRoute.name === 'record-collection-id' && schema && schema.hideSidebarInRecordPage);
    },
    amIOnline(e) {
      this.onLine = e;
    },
    addElement($event, panel) {
      const storeModule = this.addedElementPanel.storeModule || 'abstractElements';
      this.$refs.addElementModal.saveLoading = true;
      this.$store.dispatch(`${storeModule}/createObject`, {
        collection: this.addedElementPanel.collection,
        destinationBucket: this.addedElementPanel.bucket,
        object: $event,
        sort: this.addedElementPanel.sort,
        schema: this.getSchemaByName(this.addedElementPanel.schema),
        notifyLock: `see/view/${this.addedElementPanel._id}`,
      }).then((payload) => {
        this.$refs.addElementModal.saveLoading = false;
        if (payload.data.success === false) {
          Toast.open({
            message: `Impossible de sauvegarder le document ${payload.data.error
                ? `(${JSON.stringify(payload.data.error)})` : ''}`,
            type: 'is-danger',
          });
        } else {
          Toast.open({ message: 'Document créé', type: 'is-success' });
          this.$store.commit('panels/setAddedElement', undefined);
          this.$store.commit('panels/setAddedElementPanel', undefined);
        }
      }).catch((e) => {
        this.$refs.addElementModal.saveLoading = false;
        console.error(e);
        publishErrorReport({ document: $event, error: e.toString(), stacktrace: e.stack });
      });
    },
    async editPanel($event) {
      const res = await this.$store.dispatch('abstractElements/saveObject', {
        collection: 'panels',
        object: $event,
      });

      if (this.$route.params.id === res.payload.data.document._id) {
        this.showPanel = false;
        this.$nextTick(() => {
          this.showPanel = true;
        });
      }
      this.editedPanel = undefined;
    },
    async deletePanel($event) {
      await this.$store.dispatch('abstractElements/deleteObject', {
        collection: 'panels',
        object: $event,
      });
      this.$store.commit('panels/setEditedPanel', undefined);
      this.$store.commit('panels/setEditedElementPanel', undefined);
      this.$store.commit('panels/setEditingView', false);
      redirect(this.$router, ['/']);
    },
    async addPanel($event) {
      if ($event.__schemaStrategy === 'new') {
        await this.$store.dispatch('abstractElements/createObject', {
          collection: 'schemas',
          object: {
            name: `${$event.title}_schema`,
            fields: [{
              type: 'text',
              model: 'name',
              label: 'Nom'
            }, {
              type: 'date',
              model: 'date',
              label: 'Date'
            }]
          }
        });
        $event.templateEditor = 'WYSIWYG';
        $event.templateComponent = {
          fields: [
            {
              type: 'text',
              model: 'name',
              label: 'Nom',
              styles: [
                'expand',
              ],
            },
            {
              type: 'date',
              model: 'date',
              label: 'Date',
              styles: [
                'tag',
              ],
              renderComponent: 'date',
            },
          ],
          name: 'CardRecordTemplate',
        };
        $event.schema = `${$event.title}_schema`;
      }
      this.$store.dispatch('abstractElements/createObject', {
        collection: 'panels',
        object: $event,
      }).then((payload) => {
        redirect(this.$router, [`/panel/${payload.data.document._id}`]);
        this.$store.commit('panels/setAddedPanel', undefined);
      });
    },
    getSchemaByName(schemaName) {
      const schemas = this.$store.state.abstractElements.objects.schemas.objects;
      for (let i = 0; i < schemas.length; i++) {
        if (schemas[i].name === schemaName) {
          return schemas[i];
        }
      }
      return undefined;
    },
    closeTemplatePanelCreator() {
      if (this.showPanelFromTemplateModal !== false) {
        this.showPanelFromTemplateModal = false;
        this.$store.commit('panels/setEditingView', undefined);
      } else {
        this.$store.commit('panels/setEditedPanel', undefined);
        this.$store.commit('panels/setEditingView', undefined);
      }
    },
  },
};
</script>
<style scoped>
.layout {
  height: 100vh;
  overflow: hidden;
}
.main-content {
  display: flex;
  width: 100vw;
}
@media print {
  .layout {
    height: auto;
    overflow: auto;
  }
}

.toolbar {
  position: absolute;
  top: 0;
  width: 100%;
  right: 0;
  z-index: 1;
}
.menu-hidden .toolbar {
  left: 300px;
}
@media print {
  .is-10 {
    width: 100%;
  }
}

.content {
  position: absolute;
  overflow: auto;
  height: calc(100vh - 46px);
  left: 0;
  width: 100%;
}
.menu-hidden .content {
  width: calc(100% - 10px);
}

.sidebar {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  /*width: 300px;*/
  z-index: 1001;
}
.menu-hidden .sidebar {
  position: absolute;
}
.main-column {
  position: relative;
  transition: left 0.2s;
  flex-grow: 1;
}
.desktop.menu-hidden .main-column {
  left: 10px;
}
.desktop.editing-view .main-column {
  right: 400px;
}
</style>
<style>
@media print {
  .no-print, .no-print * {
    display: none;
  }
  .desktop .main-column {
    left: 0 !important;
    height: auto;
    position: static !important;
  }
}
html {
  overflow-y: hidden;
}
.modal {
  z-index: 1000000;
}

.modal-card-body .form input[type=text],
.modal-card-body .form input[type=password],
.modal-card-body .form input[type=number],
.modal-card-body .form textarea,
.modal-card-body .form select {
  background: #f9f9f9 !important;
  border: 0 !important;
}

.mobile aside.section {
  padding: 0 !important;
  margin: 0;
}
.mobile aside.section > .scrollbar {
  padding-right: 0;
}

.notices {
  z-index: 1000000000;
}

.hamburger-menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 36px;
  padding-top: 1px;
  overflow: hidden;
  background: whitesmoke;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  color: #333;
  z-index: 11;
}
.hamburger-menu i::before {
  margin-top: -9px;
  font-size: 34px;
}
.main-action-button {
  position: fixed;
  bottom: 80px;
  right: 44px;
}
.mobile .view-title {
  position: fixed;
  top: 0;
  padding-top: 6px;
  height: 36px;
  left: 36px;
  right: 0;
  background: whitesmoke;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  z-index: 1000;
  padding-left: 6px;
}
.mobile-view-title .collaborative-user-preview .b-tooltip {
  top: 2px;
}
.layout-wrapper {
  display: flex;
}
</style>
