<template>
  <div class="document-table">
    <div v-if="element && element !== ''">
      <div v-for="f in schema.fields" :key="f.model" :class="f.styleClasses ? 'record-field ' + f.styleClasses.join(' ') : 'record-field'">
        <div class="field-label">
          {{ f.label }}
        </div>
        <div class="field-value" :data-value-for="f.label">
          {{ displayValue(element, f) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import exportFunctions from '@/core/exportFunctions';

export default {
  props: {
    element: {
      type: [Object, String],
      default: undefined,
    },
    panelConfig: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      schema: undefined,
    };
  },
  watch: {
    panelConfig: {
      handler(v) {
        if (v) {
          this.schema = this.getSchemaByName(v.schema);
        }
      },
      immediate: true,
    },
  },
  methods: {
    displayValue(obj, field) {
      let res = obj[field.model];
      if (field.exportFunction) {
        let functionName = field.exportFunction;
        let functionParams = [];
        if (field.exportFunction.includes(':')) {
          functionName = field.exportFunction.split(':');
          functionParams = functionName[1].split(',');
          functionName = functionName[0];
        }
        if (exportFunctions[functionName]) {
          res = exportFunctions[functionName](obj[field.model], {
            params: functionParams,
            field,
          });
        }
      }
      if (Array.isArray(res)) {
        return res.join(',');
      }
      return res;
    },
    getSchemaByName(schemaName) {
      const schemas = this.$store.state.abstractElements.objects.schemas.objects;
      for (let i = 0; i < schemas.length; i++) {
        if (schemas[i].name === schemaName) {
          return schemas[i];
        }
      }
      return undefined;
    },
  },
};
</script>
<style scoped>
.document-table {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  border-bottom: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
}
.record-field {
  display: inline-grid;
  grid-template-columns: 100px calc(100% - 100px);
  width: 100%;
  margin: 0;
  border-top: 1px solid #dbdbdb;
  border-left: 1px solid #dbdbdb;
  border-collapse: collapse;
  align-self: stretch;
}
.quarter-width {
  width: 25%;
}
.tier-width {
  width: 33%;
}
.half-width {
  width: 50%;
}
.field-label, .field-value {
  display: inline-block;
  margin: 0;
  align-self: stretch;
  padding: 6px;
  font-size: 75%;
}

.field-label {
  background: rgb(238, 238, 238);
  font-weight: bold;
}
</style>
