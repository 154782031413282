<template>
  <span>{{ value ? new Date(value).toLocaleDateString() : '' }}</span>
</template>
<script>
export default {
  name: 'RenderComponentDate',
  simpleName: 'date',
  needField: true,
  props: {
    value: {
      type: String,
      default: undefined,
    },
  },
};
</script>
