import PermissionManager from '@/permissions/PermissionManager';
import permissionsActions from '@/permissions/actions';

export default () => {
  if (process.env.VUE_APP_CRISP_WEBSITE_ID && PermissionManager.can(permissionsActions.can_see_support_chat)) {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = process.env.VUE_APP_CRISP_WEBSITE_ID;
    const script = document.createElement('script');
    script.src = 'https://client.crisp.chat/l.js';
    script.async = 1;
    document.getElementsByTagName('head')[0].appendChild(script);
  }
};
