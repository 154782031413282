import Vue from 'vue';
import icon from './icon';
import date from './date';
import text from './text';
import printbutton from './printbutton';
import bool from './boolean';
import arraycount from './arraycount';
import tag from './tag';

Vue.component('render-component-icon', icon);
Vue.component('render-component-date', date);
Vue.component('render-component-printbutton', printbutton);
Vue.component('render-component-text', text);
Vue.component('render-component-boolean', bool);
Vue.component('render-component-arraycount', arraycount);
Vue.component('render-component-tag', tag);

export default [icon, date, printbutton, text, bool, arraycount, tag];
