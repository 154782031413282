import Vue from 'vue';
import Api from '@/core/Api';

export const state = () => ({
  pages: [],
  referenceItems: [],
  loaded: false,
});

export const mutations = {
  setContent(state, { pages, referenceItems }) {
    state.pages = pages;
    state.referenceItems = referenceItems;
    state.loaded = true;
  },
};

export const actions = {
  async load({ dispatch, commit, rootState }) {
    if (rootState.documentation.pages.length === 0) {
      const pagesRes = await this.$axios.get('https://dashy-referentiel.herokuapp.com/documentation', {
        headers: { authorization: `apiKey ${process.env.VUE_APP_REFERENTIEL_API}` },
      });
      const refItemsRes = await this.$axios.get('https://dashy-referentiel.herokuapp.com/referentiel', {
        headers: { authorization: `apiKey ${process.env.VUE_APP_REFERENTIEL_API}` },
      });
      commit('setContent', { pages: pagesRes.data.documents, referenceItems: refItemsRes.data.documents });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
