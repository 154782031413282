import Handlebars from 'handlebars';
import Faltu from 'faltu';
import { ToastProgrammatic as Toast } from 'buefy';
import sift from 'sift';
import json5 from 'json5';
import Fuse from 'fuse.js';
import templateVariables from '@/core/templateVariables';
import Api from '@/core/Api';
import deepClone from '@/core/utils/deepClone';

const compileQueryPart = (queryPart, rootState, panelParams, additionnalTemplateVars) => {
  if (queryPart) {
    try {
      if (typeof queryPart === 'string') {
        const template = Handlebars.compile(queryPart);
        let templateVars = templateVariables.get(rootState, panelParams);
        if (additionnalTemplateVars !== undefined) {
          templateVars = {
            ...templateVars,
            ...additionnalTemplateVars
          };
        }
        queryPart = template(templateVars);
      } else {
        queryPart = JSON.stringify(queryPart);
      }
    } catch (e) {
      console.error('malformed query JSON: ', e, queryPart);
      return '[]';
    }
  }
  return queryPart;
};

const applyFilterAndSearch = (objects, filter, query, search, rootState, panelParams, additionnalTemplateVars) => {
  let filteredObjects = objects;
  let finalFilter;

  if ((filter && filter.query) || (query && query.length)) {

    if (filter && filter.query) {
      filter = filter.query;
      if (typeof filter === 'string') {
        filter = json5.parse(compileQueryPart(filter, rootState, panelParams));
      }
    }
    if (filter && query && query.length) {

      if (!Array.isArray(filter)) {
        filter = [filter];
      }
      finalFilter = { $and: [...filter, ...query] };
    } else if (query && query.length) {
      if (typeof query === 'string') {
        query = json5.parse(compileQueryPart(query, rootState, panelParams));
      }
      if (query.length === 1 || !Array.isArray(query)) {
        finalFilter = query;
      } else {
        finalFilter = { $and: query };
      }
    } else {

      finalFilter = filter;
    }
    if (finalFilter.length === 1) {
      finalFilter = finalFilter[0];
    } else if (finalFilter.length > 1) {
      finalFilter = { $and: finalFilter };
    }
    filteredObjects = objects.filter(sift(finalFilter));
  }
  if (search && search.searchTerm) {
    if (search.keys && search.keys.indexOf('_id') !== -1) {
      search.keys.splice(search.keys.indexOf('_id'), 1);
    }

    const options = {
      shouldSort: true,
      threshold: 0.2,
      ignoreLocation: true,
      limit: 50,
      tokenize: false,
      keys: search.keys || [],
    };
    const fuse = new Fuse(filteredObjects, options);
    if (search.searchTerm && search.searchTerm !== '') {
      filteredObjects = fuse.search(search.searchTerm).map(o => o.item);
    }
  }
  return { filteredObjects, finalFilter };
};

export const state = () => ({
  editedObject: undefined,
  objects: {},
});

export const actions = {
  setPage ({ commit }, { bucket, page }) {
    commit('setPage', { bucket, page });
  },
  setPerPage ({ commit }, { bucket, perPage }) {
    commit('setPerPage', { bucket, perPage });
  },
  filterObjects({ rootState, commit, dispatch }, {
    bucket, filter, search, query, panelParams,
  }) {
    if (rootState.abstractElements.objects[bucket] && rootState.abstractElements.objects[bucket].objects) {
      let filteredObjects = JSON.parse(JSON.stringify(rootState.abstractElements.objects[bucket].objects));

      const res = applyFilterAndSearch(filteredObjects, filter, query, search, rootState, panelParams);
      commit('setFilteredObjects', {
        bucket,
        documents: res.filteredObjects,
        filter: res.finalFilter,
        searchTerm: search.searchTerm,
      });

    } else {
      commit('setFilteredObjects', {
        bucket,
        documents: [],
        filter: undefined,
        searchTerm: undefined,
      });
    }
    dispatch('setPage', { bucket, page: 1 });
  },

  async queryObject({ context, rootState }, { collection, id }) {
    const payload = await Api.get(`/${collection}/${id}`);
    const { documents } = payload.data;
    const { error } = payload.data;
    const { success } = payload.data;
    return {
      document: documents ? documents[0] : undefined,
      error,
      success,
    };
  },

  async fetchAllObjects({ commit, rootState }) {},

  async fetchObjects({ commit, rootState }, {
    collection, destinationBucket, query, sort, aggregate, limit, panelParams, additionnalTemplateVars, page, perPage, filter, search
  }) {
    console.time('FETCHOBJECTS '+ collection);
    if (!destinationBucket) {
      destinationBucket = collection;
    }

    const params = {
      query: compileQueryPart(query, rootState, panelParams, additionnalTemplateVars),
      aggregate: compileQueryPart(aggregate, rootState, panelParams, additionnalTemplateVars),
      sort: compileQueryPart(sort, rootState, panelParams, additionnalTemplateVars),
      limit,
    };
    commit('setLoading', { collection, bucket: destinationBucket, loading: true });
    let res; let
      error;
    try {
      res = await Api.get(`/${collection}`, { params });
    } catch (e) {
      commit('setLoading', { collection, bucket: destinationBucket, loading: false });
      if (e.response.data) {
        error = { ...e.response.data.error };
      }
      throw new Error(e);
    }
    commit('setObjects', {
      collection, destinationBucket, documents: res.data.documents, error, source: 'server', page, perPage, rootState, filter, query, search, panelParams
    });
    commit('setLoading', { collection, bucket: destinationBucket, loading: false });
    console.timeEnd('FETCHOBJECTS '+ collection);
    return res.documents;
  },

  async saveObject({ commit }, {
    destinationBucket, collection, object, objects, sort, offlineDocSelected, notifyLock
  }) {
    if (!destinationBucket) {
      destinationBucket = collection;
    }
    let payload;
    if (object) {
      const objectKeys = Object.keys(object);
      const keysOmmitted = {};
      for (let i = 0; i < objectKeys.length; i++) {
        if (objectKeys[i].startsWith('__')) {
          keysOmmitted[objectKeys[i]] = object[objectKeys[i]];
          delete object[objectKeys[i]];
        }
      }

      if (!offlineDocSelected) {
        try {
          let params = '';
          if (notifyLock) {
            params = `?notifyLock=${notifyLock}`;
          }
          payload = await Api.post(`/${collection}/${object._id}` + params, object);
          if (payload.data.success && payload.data.modified === 1) {
            object = { ...keysOmmitted, ...payload.data.document };
          } else {
            Toast.open({ message: 'Erreur à l\'enregistrement', type: 'is-danger' });
            commit('statusMessages/setStatusMessage', {
              text: 'Erreur à l\'enregistrement: ' + JSON.stringify(payload.data),
              moreInfo: 'Il peut être important de faire suivre ce message à l\'administrateur systeme',
              type: 'is-danger',
            }, { root: true });
            return { error: e };
          }
        } catch (e) {
          return { error: e };
        }
        commit('updateObject', {
          collection, bucket: destinationBucket, object, sort,
        });
      } else {
        console.log('TODO document update when offline');
      }

      Toast.open({ message: 'Document sauvegardé', type: 'is-success' });

      return { payload };
    } if (objects && objects.length) {
      const keysOmmitted = {};
      objects = objects.map((o) => {
        const objectKeys = Object.keys(o);
        keysOmmitted[o._id] = {};
        for (let i = 0; i < objectKeys.length; i++) {
          if (objectKeys[i].startsWith('__')) {
            keysOmmitted[o._id][objectKeys[i]] = o[objectKeys[i]];
            delete o[objectKeys[i]];
          }
        }
        return o;
      });
      payload = await Api.post(`/${collection}`, objects);
      return { payload, data: payload.data, status: payload.status };
    }
  },

  async createObject({ commit, rootState }, {
    destinationBucket, collection, object, objects, schema, sort, offlineDocSelected, notifyLock
  }) {
    if (!destinationBucket) {
      destinationBucket = collection;
    }
    schema = schema || {};

    let payload;
    try {
      if (schema) {
        if (object !== undefined) {
          if (!object._metadatas) {
            object._metadatas = {};
          }
          object._metadatas.schemaName = schema.name;
        }
        if (objects && objects.length) {
          for (let i = 0; i < objects.length; i++) {
            if (!objects[i]._metadatas) {
              objects[i]._metadatas = {};
            }
            objects[i]._metadatas.schemaName = schema.name;
          }
        }
      }
      if (!offlineDocSelected) {
        payload = await Api.put(`/${collection}`, { object, objects, dynamicValues: schema.dynamicValues, notifyLock });

        if (!payload.data.success) {
          return payload;
        }
        object = payload.data.document;
      } else {
        if (!offlineDocSelected.additionnalSyncSteps) {
          offlineDocSelected.additionnalSyncSteps = [];
        }
        object.__tempId = `tempId_${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`;
        offlineDocSelected.additionnalSyncSteps.push({
          type: 'create',
          collection,
          object,
          objects,
          dynamicValues: schema.dynamicValues,
        });
      }
    } catch (e) {
      const errorMessage = e.message || 'undefined error';
      Toast.open({ message: `Could not save object (${errorMessage})`, type: 'is-danger' });
      return e.response;
    }
    Toast.open({ message: 'Document créé', type: 'is-success' });
    if (!offlineDocSelected) {
      if (payload && payload.data && payload.data.documents) {
        for (let i = 0; i < payload.data.documents.length; i++) {
          commit('addObject', {
            collection, bucket: destinationBucket, object: payload.data.documents[i], sort, rootState
          });
        }
      } else {
        commit('addObject', {
          collection, bucket: destinationBucket, object: payload.data.document, sort, rootState
        });
      }
      return payload;
    }
    return {
      data: {
        document: object,
      },
    };
  },

  async deleteObject({ commit }, { collection, bucket, object }) {
    const payload = await Api.delete(`/${collection}/${object._id}`);
    commit('deleteObject', { collection, bucket, object });

    Toast.open({
      message: 'Document supprimé',
      type: 'is-success',
    });

    return payload;
  },
};

export const mutations = {
  setPage(state, { bucket, page }) {
    state.objects[bucket].page = page;
    const perPage = state.objects[bucket].perPage;
    const paginationOffsetStart = (state.objects[bucket].page - 1) * perPage;
    state.objects[bucket].paginatedFilteredElements = (state.objects[bucket].filteredObjects || []).slice(paginationOffsetStart, paginationOffsetStart + perPage);
  },
  setPerPage(state, { bucket, perPage}) {
    const paginationOffsetStart = (state.objects[bucket].page - 1) * perPage;
    state.objects[bucket].paginatedFilteredElements = (state.objects[bucket].filteredObjects || []).slice(paginationOffsetStart, paginationOffsetStart + perPage);
    state.objects[bucket].perPage = perPage;
  },
  setEditedObject(state, object) {
    state.editedObject = object;
  },
  setLoading(state, { collection, bucket, loading }) {
    bucket = bucket || collection;

    if (state.objects[bucket] === undefined) {
      state.objects[bucket] = {};
    }
    state.objects[bucket].isLoading = loading;
  },
  addObject(state, {
    collection, bucket, object, sort, rootState
  }) {
    bucket = bucket || collection;
    if (state.objects[bucket] === undefined) {
      state.objects[bucket] = { objects: [] };
    }
    if (state.objects[bucket].objects === undefined) {
      state.objects[bucket].objects = [];
    }
    state.objects[bucket].objects.push(object);
    if (sort) {
      state.objects[bucket].objects = new Faltu(state.objects[bucket].objects).find({}).sort(sort).get();
    }
    state.objects = deepClone(state.objects);
    const filteredObjects = applyFilterAndSearch(
      state.objects[bucket].objects,
      state.objects[bucket].filter,
      state.objects[bucket].query,
      state.objects[bucket].search,
      rootState,
      {/* panelParams TODO */})
    .filteredObjects;

    const perPage = parseInt(state.objects[bucket].perPage || 10);
    const page = parseInt(state.objects[bucket].page || 1);
    const paginationOffsetStart = (page - 1) * perPage;
    state.objects[bucket].paginatedFilteredElements = (filteredObjects || []).slice(paginationOffsetStart, paginationOffsetStart + perPage);
  },

  setObjects(state, {
    collection, destinationBucket, documents, error, source, page, perPage, rootState, filter, query, search, panelParams
  }) {
    perPage = parseInt(perPage || state.perPage || 10);
    page = parseInt(page || 1);
    const paginationOffsetStart = (page - 1) * perPage;
    const filteredObjects = applyFilterAndSearch(documents, filter, query, search, rootState, panelParams).filteredObjects;
    state.objects[destinationBucket] = {
      page,
      perPage,
      collection,
      objects: documents,
      filteredObjects,
      paginatedFilteredElements: (filteredObjects || []).slice(paginationOffsetStart, paginationOffsetStart + perPage),
      error,
      source,
      filter,
      query,
      search,
    };
    state.objects = { ...state.objects };
  },
  //TODO check if this is correct with pagination
  //TODO search vs searchTerm to clean
  setFilteredObjects(state, {
    bucket, documents, filter, searchTerm,
  }) {
    if (state.objects[bucket]) {
      state.objects[bucket].filteredObjects = documents;
      state.objects[bucket].filter = filter;
      state.objects[bucket].searchTerm = searchTerm;
      // state.objects = { ...state.objects };
    }
  },

  updateObject(state, {
    collection, bucket, object, sort,
  }) {
    bucket = bucket || collection;
    state.editedObject = undefined;
    if (state.objects[bucket] && state.objects[bucket].objects) {
      state.objects[bucket].objects = state.objects[bucket].objects.map((b) => {
        if (b._id === object._id) {
          return object;
        }
        return b;
      });
      state.objects[bucket].filteredObjects = state.objects[bucket].filteredObjects.map((b) => {
        if (b._id === object._id) {
          return object;
        }
        return b;
      });
      if (sort) {
        state.objects[bucket].objects = new Faltu(state.objects[bucket].objects).find({}).sort(sort).get();
      }
    }
    state.objects = { ...state.objects };
    const perPage = state.objects[bucket] ? state.objects[bucket].perPage : 10;
    const paginationOffsetStart = (state.objects[bucket].page - 1) * perPage;
    state.objects[bucket].paginatedFilteredElements = (state.objects[bucket].filteredObjects || []).slice(paginationOffsetStart, paginationOffsetStart + perPage);
  },

  deleteObject(state, { collection, bucket, object }) {
    bucket = bucket || collection;
    let objectsModified = false;
    for (let i = 0; i < state.objects[bucket].objects.length; i++) {
      if (state.objects[bucket].objects[i]._id === object._id) {
        state.objects[bucket].objects.splice(i, 1);
        objectsModified = true;
      }
    }
    if (state.objects[bucket].filteredObjects) {
      for (let i = 0; i < state.objects[bucket].filteredObjects.length; i++) {
        if (state.objects[bucket].filteredObjects[i]._id === object._id) {
          state.objects[bucket].filteredObjects.splice(i, 1);
          objectsModified = true;
        }
      }
    }
    if (state.objects[bucket].paginatedFilteredElements) {
      for (let i = 0; i < state.objects[bucket].paginatedFilteredElements.length; i++) {
        if (state.objects[bucket].paginatedFilteredElements[i]._id === object._id) {
          state.objects[bucket].paginatedFilteredElements.splice(i, 1);
          objectsModified = true;
        }
      }
    }
    if (objectsModified) {
      state.objects = { ...state.objects };
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
