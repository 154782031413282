<template>
  <span>
    {{ field && field.text ? field.text : 'text' }}
  </span>
</template>
<script>
export default {
  name: 'RenderComponentText',
  simpleName: 'text',
  props: {
    field: {
      type: Object,
      default: undefined,
    },
  },
  options: {
    fields: [{
      model: 'text',
      label: 'text',
      type: 'text',
    }],
  },
};
</script>
