const slots = {};
const actions = {};

class PluginSlotManager {
  addComponentToSlot(slotName, component) {
    if (slots[slotName] === undefined) {
      slots[slotName] = [];
      slots[slotName].push(component);
    }
  }

  addAction(actionName, action) {
    actions[actionName] = action;
  }

  slot(slotName) {
    return slots[slotName];
  }

  actions() {
    return actions;
  }
}

export default new PluginSlotManager();
