
export const state = () => ({
  fileDisplayed: undefined,
});

export const mutations = {
  setFileDisplayed(state, file) {
    state.fileDisplayed = file;
  },
};
export const actions = {
  setFileDisplayed({ commit }, file) {
    commit('setFileDisplayed', file);
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
