import vfgfields from '@/core/vfgfields';

const fields = vfgfields.fields;

const devtools = {
  listFields () {
    return fields;
  }
};

window.$dashy = devtools;
