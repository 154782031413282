<template>
  <div :class="[size, {'is-expanded': expanded}]" class="datepicker control">
    <div
      v-if="!isMobile || inline"
      ref="dropdown"
      :disabled="disabled"
      :inline="inline"
    >
      <b-input
        v-if="!inline"
        ref="input"
        slot="trigger"
        :value="valueDisplayed || formatValue(computedValue)"
        :placeholder="placeholder"
        :size="size"
        :icon="icon"
        :icon-pack="iconPack"
        :rounded="rounded"
        :loading="loading"
        :disabled="disabled"
        :readonly="!editable"
        v-bind="$attrs"
        :use-html5-validation="useHtml5Validation"
        autocomplete="off"
        @click.native="onInputClick"
        @keyup.native.enter="togglePicker(true)"
        @change.native="onChange($event.target.value)"
        @focus="handleOnFocus"
        @blur="onBlur"
      />
      <portal v-if="isDropdownActive" :disabled="disabled" to="dropdownContent">
        <div :class="`datepicker-overlay`" @click="isDropdownActive = false" />
        <div ref="datepicker-dropdown" :class="`datepicker-dropdown ${dropdownClass}`" :style="computeDropdownStyle()">
          <header class="datepicker-header">
            <template v-if="$slots.header !== undefined && $slots.header.length">
              <slot name="header" />
            </template>
            <div
              v-else
              :class="size"
              class="pagination field is-centered"
            >
              <a
                v-show="!showPrev && !disabled"
                :disabled="disabled"
                class="pagination-previous"
                role="button"
                href="#"
                @click.prevent="prev"
                @keydown.enter.prevent="prev"
                @keydown.space.prevent="prev"
              >

                <b-icon
                  :icon="iconPrev"
                  :pack="iconPack"
                  both
                  type="is-primary is-clickable"
                />
              </a>
              <a
                v-show="!showNext && !disabled"
                :disabled="disabled"
                class="pagination-next"
                role="button"
                href="#"
                @click.prevent="next"
                @keydown.enter.prevent="next"
                @keydown.space.prevent="next"
              >

                <b-icon
                  :icon="iconNext"
                  :pack="iconPack"
                  both
                  type="is-primary is-clickable"
                />
              </a>
              <div class="pagination-list">
                <div class="field">
                  <b-select
                    v-if="!isTypeMonth"
                    v-model="focusedDateData.month"
                    :disabled="disabled"
                    :size="size"
                  >
                    <option
                      v-for="(month, index) in monthNames"
                      :key="month"
                      :value="index"
                    >
                      {{ month }}
                    </option>
                  </b-select>
                  <b-select
                    v-model="focusedDateData.year"
                    :disabled="disabled"
                    :size="size"
                  >
                    <option
                      v-for="year in listOfYears"
                      :key="year"
                      :value="year"
                    >
                      {{ year }}
                    </option>
                  </b-select>
                </div>
              </div>
            </div>
          </header>

          <div
            v-if="!isTypeMonth"
            class="datepicker-content"
          >
            <b-datepicker-table
              v-model="computedValue"
              :day-names="dayNames"
              :month-names="monthNames"
              :first-day-of-week="firstDayOfWeek"
              :rules-for-first-week="rulesForFirstWeek"
              :min-date="minDate"
              :max-date="maxDate"
              :focused="focusedDateData"
              :disabled="disabled"
              :unselectable-dates="unselectableDates"
              :unselectable-days-of-week="unselectableDaysOfWeek"
              :selectable-dates="selectableDates"
              :events="events"
              :indicators="indicators"
              :date-creator="dateCreator"
              :type-month="isTypeMonth"
              :nearby-month-days="nearbyMonthDays"
              :nearby-selectable-month-days="nearbySelectableMonthDays"
              :show-week-number="showWeekNumber"
              :range="range"
              :multiple="multiple"
              @close="togglePicker(false)"
            />
          </div>
          <div v-else>
            <b-datepicker-month
              v-model="computedValue"
              :month-names="monthNames"
              :min-date="minDate"
              :max-date="maxDate"
              :focused="focusedDateData"
              :disabled="disabled"
              :unselectable-dates="unselectableDates"
              :unselectable-days-of-week="unselectableDaysOfWeek"
              :selectable-dates="selectableDates"
              :events="events"
              :indicators="indicators"
              :date-creator="dateCreator"
              @close="togglePicker(false)"
            />
          </div>
          <footer
            v-if="$slots.default !== undefined && $slots.default.length"
            class="datepicker-footer"
          >
            <slot />
          </footer>
        </div>
      </portal>
    </div>

    <b-input
      v-else
      ref="input"
      :type="!isTypeMonth ? 'date' : 'month'"
      :value="formatNative(computedValue)"
      :placeholder="placeholder"
      :size="size"
      :icon="icon"
      :icon-pack="iconPack"
      :loading="loading"
      :max="formatNative(maxDate)"
      :min="formatNative(minDate)"
      :disabled="disabled"
      :readonly="false"
      v-bind="$attrs"
      :use-html5-validation="useHtml5Validation"
      autocomplete="off"
      @change.native="onChangeNativePicker"
      @focus="onFocus"
      @blur="onBlur"
    />
  </div>
</template>

<script>
/* eslint-disable */
import FormElementMixin from 'buefy/src/utils/FormElementMixin';
import { isMobile } from 'buefy/src/utils/helpers';
import config from 'buefy/src/utils/config';

import Dropdown from 'buefy/src/components/dropdown/Dropdown';
import DropdownItem from 'buefy/src/components/dropdown/DropdownItem';
import Input from 'buefy/src/components/input/Input';
import Field from 'buefy/src/components/field/Field';
import Select from 'buefy/src/components/select/Select';
import Icon from 'buefy/src/components/icon/Icon';

import DatepickerTable from './DatePickerTable';
import DatepickerMonth from 'buefy/src/components/datepicker/DatepickerMonth';
import i18n from 'i18n/components/ui/DatePicker.json';

const defaultDateFormatter = (date, vm) => {
  const formatDatePart = (part) => {
    part = '' + part;
    if(part.length === 1) {
      part = '0' + part;
    }
    return part;
  }

  return formatDatePart(date.getDate()) + '/' + formatDatePart(date.getMonth() + 1) + '/' + date.getFullYear();
};

const defaultDateParser = (date, vm) => {
  if (!vm.isTypeMonth) { return new Date(Date.parse(date)); }
  if (date) {
    const s = date.split('/');
    const year = s[0].length === 4 ? s[0] : s[1];
    const month = s[0].length === 2 ? s[0] : s[1];
    if (year && month) {
      return new Date(parseInt(year, 10), parseInt(month - 1, 10), 1, 0, 0, 0, 0);
    }
  }
  return null;
};

export default {
  name: 'BDatepicker',
  components: {
    [DatepickerTable.name]: DatepickerTable,
    [DatepickerMonth.name]: DatepickerMonth,
    [Input.name]: Input,
    [Field.name]: Field,
    [Select.name]: Select,
    [Icon.name]: Icon,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem
  },
  mixins: [FormElementMixin],
  inheritAttrs: false,
  props: {
    dropdownClass: {
      type: String,
      default: ''
    },
    value: {
      type: [Date, Array]
    },
    valueDisplayed: {
      type: String
    },
    customDayNames: {
      type: Array,
      default: () => {
        if (Array.isArray(config.defaultDayNames)) {
          return config.defaultDayNames;
        } else {
          return [];
        }
      }
    },
    customMonthNames: {
      type: Array,
      default: () => {
        if (Array.isArray(config.defaultMonthNames)) {
          return config.defaultMonthNames;
        } else {
          return [];
        }
      }
    },
    firstDayOfWeek: {
      type: Number,
      default: () => {
        if (typeof config.defaultFirstDayOfWeek === 'number') {
          return config.defaultFirstDayOfWeek;
        } else {
          return 1;
        }
      }
    },
    inline: Boolean,
    minDate: Date,
    maxDate: Date,
    focusedDate: Date,
    placeholder: String,
    editable: Boolean,
    disabled: Boolean,
    unselectableDates: Array,
    unselectableDaysOfWeek: {
      type: Array,
      default: () => { return config.defaultUnselectableDaysOfWeek; }
    },
    selectableDates: Array,
    dateFormatter: {
      type: Function,
      default: (date, vm) => {
        if (typeof config.defaultDateFormatter === 'function') {
          return config.defaultDateFormatter(date);
        } else {
          return defaultDateFormatter(date, vm);
        }
      }
    },
    dateParser: {
      type: Function,
      default: (date, vm) => {
        if (typeof config.defaultDateParser === 'function') {
          return config.defaultDateParser(date);
        } else {
          return defaultDateParser(date, vm);
        }
      }
    },
    dateCreator: {
      type: Function,
      default: () => {
        if (typeof config.defaultDateCreator === 'function') {
          return config.defaultDateCreator();
        } else {
          return new Date();
        }
      }
    },
    mobileNative: {
      type: Boolean,
      default: () => {
        return config.defaultDatepickerMobileNative;
      }
    },
    position: String,
    events: Array,
    indicators: {
      type: String,
      default: 'dots'
    },
    openOnFocus: Boolean,
    iconPrev: {
      type: String,
      default: config.defaultIconPrev
    },
    iconNext: {
      type: String,
      default: config.defaultIconNext
    },
    yearsRange: {
      type: Array,
      default: () => {
        return config.defaultDatepickerYearsRange;
      }
    },
    type: {
      type: String,
      validator: (value) => {
        return [
          'month'
        ].includes(value);
      }
    },
    nearbyMonthDays: {
      type: Boolean,
      default: () => config.defaultDatepickerNearbyMonthDays
    },
    nearbySelectableMonthDays: {
      type: Boolean,
      default: () => config.defaultDatepickerNearbySelectableMonthDays
    },
    showWeekNumber: {
      type: Boolean,
      default: () => config.defaultDatepickerShowWeekNumber
    },
    rulesForFirstWeek: {
      type: Number,
      default: () => 4
    },
    range: {
      type: Boolean,
      default: false
    },
    closeOnClick: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const focusedDate = (Array.isArray(this.value) ? this.value[0] : (this.value)) ||
            this.focusedDate || this.dateCreator();

    let dayNames = this.customDayNames;
    if(!dayNames || !dayNames.length) {
      dayNames = [
        i18n.Su,
        i18n.M,
        i18n.Tu,
        i18n.W,
        i18n.Th,
        i18n.F,
        i18n.S
      ];
    }
    let monthNames = this.customMonthNames;
    if(!monthNames || !monthNames.length) {
      monthNames = [
        i18n.January,
        i18n.February,
        i18n.March,
        i18n.April,
        i18n.May,
        i18n.June,
        i18n.July,
        i18n.August,
        i18n.September,
        i18n.October,
        i18n.November,
        i18n.December
      ];
    }
    return {
      dayNames,
      monthNames,
      isDropdownActive: false,
      dateSelected: this.value,
      focusedDateData: {
        month: focusedDate.getMonth(),
        year: focusedDate.getFullYear()
      },
      _elementRef: 'input',
      _isDatepicker: true
    };
  },
  computed: {
    computedValue: {
      get () {
        return this.dateSelected;
      },
      set (value) {
        this.updateInternalState(value);
        if (!this.multiple) { this.togglePicker(false); }
        this.$emit('input', value);
      }
    },
    /*
        * Returns an array of years for the year dropdown. If earliest/latest
        * dates are set by props, range of years will fall within those dates.
        */
    listOfYears () {
      let latestYear = this.focusedDateData.year + this.yearsRange[1];
      if (this.maxDate && this.maxDate.getFullYear() < latestYear) {
        latestYear = Math.max(this.maxDate.getFullYear(), this.focusedDateData.year);
      }

      let earliestYear = this.focusedDateData.year + this.yearsRange[0];
      if (this.minDate && this.minDate.getFullYear() > earliestYear) {
        earliestYear = Math.min(this.minDate.getFullYear(), this.focusedDateData.year);
      }

      const arrayOfYears = [];
      for (let i = earliestYear; i <= latestYear; i++) {
        arrayOfYears.push(i);
      }

      return arrayOfYears.reverse();
    },

    showPrev () {
      if (!this.minDate) { return false; }
      if (this.isTypeMonth) {
        return this.focusedDateData.year <= this.minDate.getFullYear();
      }
      const dateToCheck = new Date(this.focusedDateData.year, this.focusedDateData.month);
      const date = new Date(this.minDate.getFullYear(), this.minDate.getMonth());
      return (dateToCheck <= date);
    },

    showNext () {
      if (!this.maxDate) { return false; }
      if (this.isTypeMonth) {
        return this.focusedDateData.year >= this.maxDate.getFullYear();
      }
      const dateToCheck = new Date(this.focusedDateData.year, this.focusedDateData.month);
      const date = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth());
      return (dateToCheck >= date);
    },

    isMobile () {
      return this.mobileNative && isMobile.any();
    },

    isTypeMonth () {
      return this.type === 'month';
    }
  },
  watch: {
    /**
        * When v-model is changed:
        *   1. Update internal value.
        *   2. If it's invalid, validate again.
        */
    value (value) {
      this.updateInternalState(value);
      if (!this.multiple) { this.togglePicker(false); }
      !this.isValid && this.$refs.input.checkHtml5Validity();
    },

    focusedDate (value) {
      if (value) {
        this.focusedDateData = {
          month: value.getMonth(),
          year: value.getFullYear()
        };
      }
    },

    /*
        * Emit input event on month and/or year change
        */
    'focusedDateData.month' (value) {
      this.$emit('change-month', value);
    },
    'focusedDateData.year' (value) {
      this.$emit('change-year', value);
    }
  },
  created () {
    if (typeof window !== 'undefined') {
      document.addEventListener('keyup', this.keyPress);
    }
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      document.removeEventListener('keyup', this.keyPress);
    }
  },
  methods: {
    computeDropdownStyle () {
        const rect = this.$refs['input'].$el.getBoundingClientRect();
        return { left: `${rect.left}px`, top: `${rect.top + 32}px` };
    },
    /*
        * Parse string into date
        */
    onChange (value) {
      const date = this.dateParser(value, this);
      if (date && (!isNaN(date) ||
                (Array.isArray(date) && date.length === 2 && !isNaN(date[0]) && !isNaN(date[1])))) {
        this.computedValue = date;
      } else {
        // Force refresh input value when not valid date
        this.computedValue = null;
        this.$refs.input.newValue = this.computedValue;
      }
    },

    /*
        * Format date into string
        */
    formatValue (value) {
      if (Array.isArray(value)) {
        const isArrayWithValidDates = Array.isArray(value) && value.every(v => !isNaN(v));
        return isArrayWithValidDates ? this.dateFormatter(value, this) : null;
      }
      return (value && !isNaN(value)) ? this.dateFormatter(value, this) : null;
    },

    /*
        * Either decrement month by 1 if not January or decrement year by 1
        * and set month to 11 (December) or decrement year when 'month'
        */
    prev () {
      if (this.disabled) { return; }

      if (this.isTypeMonth) {
        this.focusedDateData.year -= 1;
      } else if (this.focusedDateData.month > 0) {
        this.focusedDateData.month -= 1;
      } else {
        this.focusedDateData.month = 11;
        this.focusedDateData.year -= 1;
      }
    },

    /*
        * Either increment month by 1 if not December or increment year by 1
        * and set month to 0 (January) or increment year when 'month'
        */
    next () {
      if (this.disabled) { return; }

      if (this.isTypeMonth) {
        this.focusedDateData.year += 1;
      } else if (this.focusedDateData.month < 11) {
        this.focusedDateData.month += 1;
      } else {
        this.focusedDateData.month = 0;
        this.focusedDateData.year += 1;
      }
    },

    formatNative (value) {
      return this.isTypeMonth
        ? this.formatYYYYMM(value) : this.formatYYYYMMDD(value);
    },

    /*
        * Format date into string 'YYYY-MM-DD'
        */
    formatYYYYMMDD (value) {
      const date = new Date(value);
      if (value && !isNaN(date)) {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return year + '-' +
                    ((month < 10 ? '0' : '') + month) + '-' +
                    ((day < 10 ? '0' : '') + day);
      }
      return '';
    },

    /*
        * Format date into string 'YYYY-MM'
        */
    formatYYYYMM (value) {
      const date = new Date(value);
      if (value && !isNaN(date)) {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        return year + '-' +
                    ((month < 10 ? '0' : '') + month);
      }
      return '';
    },

    /*
        * Parse date from string
        */
    onChangeNativePicker (event) {
      const date = event.target.value;
      this.computedValue = date ? new Date(date + 'T00:00:00') : null;
    },

    updateInternalState (value) {
      const currentDate = Array.isArray(value)
        ? (!value.length ? this.dateCreator() : value[0])
        : (!value ? this.dateCreator() : value);
      this.focusedDateData = {
        month: currentDate.getMonth(),
        year: currentDate.getFullYear()
      };
      this.dateSelected = value;
    },

    /*
        * Toggle datepicker
        */
    togglePicker (active) {
      if (this.closeOnClick) {
        this.isDropdownActive = typeof active === 'boolean'
          ? active
          : !this.isDropdownActive;
      }
    },

    /*
        * Call default onFocus method and show datepicker
        */
    handleOnFocus (event) {
      this.onFocus(event);
      if (this.openOnFocus) {
        this.togglePicker(true);
      }
    },

    /*
        * Toggle dropdown
        */
    toggle () {
      if (this.mobileNative && this.isMobile) {
        const input = this.$refs.input.$refs.input;
        input.focus();
        input.click();
        return;
      }
      this.isDropdownActive = !this.isDropdownActive;
    },

    /*
        * Avoid dropdown toggle when is already visible
        */
    onInputClick (event) {
      if (this.isDropdownActive) {
        event.stopPropagation();
      }
      this.isDropdownActive = true;
    },

    /**
         * Keypress event that is bound to the document.
         */
    keyPress (event) {
      // Esc key
      if (this.isDropdownActive && event.keyCode === 27) {
        this.togglePicker(false);
      }
    }
  }
};
</script>
<style>
.datepicker-dropdown {
  position: absolute;
  z-index: 100000001;
}

.datepicker-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100000000;
}

.datepicker .select select {
    cursor: pointer;
    display: block;
    font-size: 1em;
    max-width: 100%;
    outline: none;
}
.datepicker-dropdown {
  background: white;
  margin-top: 8px;
  min-width: 330px;
  padding-top: 14px;
  border-radius: 4px;
  box-shadow: 0 2px 3px hsla(0,0%,4%,.1), 0 0 0 1px hsla(0,0%,4%,.1);
  padding-bottom: 10px;
}
.datepicker-dropdown .input, .datepicker-dropdown .select select {
    background-color: #fff;
    border-color: #dbdbdb;
    border-radius: 4px;
    color: #363636;
    min-width: 124px;
}
.datepicker-dropdown .input, .datepicker-dropdown .pagination-ellipsis, .datepicker-dropdown .pagination-link, .datepicker-dropdown .pagination-next, .datepicker-dropdown .pagination-previous, .datepicker-dropdown .select select {
    align-items: center;
    border-radius: 4px;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.25em;
    justify-content: flex-start;
    line-height: 1.5;
    padding: calc(.375em - 1px) calc(.625em - 1px);
    position: relative;
    vertical-align: top;
}
.datepicker-dropdown .pagination-next {
  margin-right: 10px;
}
.datepicker-dropdown .pagination-previous {
  margin-left: 10px;
}

.datepicker-dropdown .control {
    box-sizing: border-box;
    clear: both;
    font-size: 1rem;
    position: relative;
    text-align: left;
}
.datepicker-dropdown .pagination > *, .datepicker-dropdown .control {
  display: inline-flex;
}

.dropdown-item {
    color: #4a4a4a;
    display: block;
    font-size: .875rem;
    line-height: 1.5;
    padding: .375rem 1rem;
    position: relative;
}
.datepicker-dropdown .datepicker-header {
    padding-bottom: .875rem;
    margin-bottom: .875rem;
    border-bottom: 1px solid #dbdbdb;
}
.datepicker-dropdown .datepicker-table {
    display: table;
    margin: 0 auto;
}
.datepicker-dropdown .datepicker-header {
    padding-bottom: .875rem;
    margin-bottom: .875rem;
    border-bottom: 1px solid #dbdbdb;
}
.datepicker-dropdown .datepicker-table .datepicker-header {
    display: table-header-group;
}
.datepicker-dropdown .datepicker-table .datepicker-body {
    display: table-row-group;
}
.datepicker-dropdown .datepicker-table .datepicker-body .datepicker-row {
    display: table-row;
}
.datepicker-dropdown .datepicker-table .datepicker-cell {
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    border-radius: 4px;
    padding: .5rem .75rem;
}
.datepicker-dropdown .datepicker-table .datepicker-body .datepicker-cell.is-unselectable {
    color: #b5b5b5;
    user-select: none;
}
.dropdown.is-active .dropdown-menu, .dropdown.is-hoverable:hover .dropdown-menu {
    display: block;
}

.dropdown-menu {
    display: none;
    left: 0;
    min-width: 12rem;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    z-index: 20;
}
.datepicker-dropdown .dropdown-content {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 3px hsla(0,0%,4%,.1), 0 0 0 1px hsla(0,0%,4%,.1);
}

.datepicker-dropdown .datepicker-table .datepicker-cell {
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    border-radius: 4px;
    padding: .5rem .75rem;
}

.datepicker-dropdown .datepicker-table .datepicker-body .datepicker-cell.is-today {
    border: 1px solid rgba(121,87,213,.5);
}

.datepicker-dropdown .datepicker-table .datepicker-body .datepicker-cell.is-selected {
    background-color: #7957d5;
    color: #fff;
}
.datepicker-dropdown a {
  text-decoration: none;
  color: black;
}

.datepicker-dropdown .datepicker-table .datepicker-body .datepicker-cell.is-selected.is-first-selected {
    background-color: #7957d5;
    color: #fff;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
}

.datepicker-dropdown .datepicker-table .datepicker-body .datepicker-cell.is-selected {
    background-color: #7957d5AA;
    color: #fff;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.datepicker-dropdown .datepicker-table .datepicker-body .datepicker-cell.is-selected.is-last-selected {
    background-color: #7957d5;
    color: #fff;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
}
.datepicker-dropdown .datepicker-table .datepicker-body .datepicker-cell.is-selectable.is-within-hovered-range.is-last-hovered {
    background-color: #7a7a7a;
    color: #dbdbdb;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
}
.datepicker-dropdown .datepicker-table .datepicker-body .datepicker-cell.is-selectable.is-within-hovered-range.is-first-hovered {
    background-color: #7a7a7a;
    color: #dbdbdb;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
}
.datepicker-dropdown .datepicker-table .datepicker-body .datepicker-cell.is-selectable.is-within-hovered-range.is-within-hovered {
    background-color: #f5f5f5;
    color: #0a0a0a;
    border-radius: 0;
}
</style>
