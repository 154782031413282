import Vue from 'vue';
import Api from '@/core/Api';

const loadComponents = function (config) {
  if (config.components) {
    for (let i = 0; i < config.components.length; i++) {
      Vue.component(config.components[i].name, config.components[i]);
    }
  }
};

export const state = () => ({
  config: undefined,
  showPanelFromTemplateModal: false,
  fuzzySearch: true,
  menuHidden: false,
  selection: [],
  batchEditModalOpened: false,
  backendPagination: false,
});

export const mutations = {
  setConfig(state, config) {
    state.config = config;
  },
  setShowPanelFromTemplateModal(state, show) {
    state.showPanelFromTemplateModal = show;
  },
  setFuzzySearch(state, fuzzySearch) {
    state.fuzzySearch = fuzzySearch;
  },
  setMenuHidden(state, menuHidden) {
    state.menuHidden = menuHidden;
  },
  setSelection(state, selection) {
    state.selection = selection;
  },
  setBatchEditModalOpened(state, batchEditModalOpened) {
    state.batchEditModalOpened = batchEditModalOpened;
  },
  setBackendPagination(state, backendPagination) {
    state.backendPagination = backendPagination;
  },
};

export const actions = {
  async fetchConfig({ dispatch, commit, rootState }) {
    let payload;
    try {
      payload = await Api.get('/config');
      if (payload.data.success) {
        const config = payload.data.documents[0] || {};
        if (config.title) {
          document.title = config.title;
        }
        loadComponents(config);
        commit('setConfig', config);
      }
    } catch (error) {
      dispatch('errors/requestError', { payload, error, message: 'Cannot fetch config' }, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
