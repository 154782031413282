export default {
  count: (e) => {
    if (e) {
      return e.length;
    }
  },
  getOiNumber: (e) => {
    if (!e) {
      return;
    }

    if (e.length) {
      return e.map((doc) => doc.oi_id).join(',');
    }
    return e.oi_id;
  },
  key: (e, { params }) => {
    const keyName = params[0];
    if (!e) {
      return;
    }
    if (e.length && e.map) {
      return e.map((doc) => doc[keyName]).join(',');
    }
    return e[keyName];
  },
  hideUnlistedValues: (e, { field }) => {
    if (Array.isArray(e)) {
      const res = [];
      const fieldValues = field.values.map((v) => (typeof v === 'string' ? v : v.value));
      for (let i = 0; i < e.length; i++) {
        if (fieldValues.includes(e[i])) {
          res.push(e[i]);
        }
      }
      return res;
    }
    return e;
  },
};
