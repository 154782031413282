import PermissionManager from '@/permissions/PermissionManager';
import integrateChatIfNeeded from '@/core/integrateChatIfNeeded';
import Api from '@/core/Api';
import socket from '@/core/socket';
import router from '@/router'
import getTokenFromHash from '@/core/utils/getTokenFromHash';


export const state = () => ({
  config: {
    showModalWhenLoggedOut: true,
  },
  modalVisible: false,
  loginLoading: false,
  lastError: undefined,
  user: undefined,
  seeAsUser: undefined,
  userDetails: undefined,
  currentWorkgroup: undefined,
  forgotPasswordMode: false,
  errorReporting: JSON.parse(localStorage.getItem('error_reporting') || 'false'),
});

export const mutations = {
  setModalVisible(state, visible) {
    state.modalVisible = visible;
    state.lastError = undefined;
    state.loginLoading = false;
    state.forgotPasswordMode = false;
  },
  setLoginLoading(state, loading) {
    state.loginLoading = loading;
  },
  setErrorReporting(state, errorReporting) {
    state.errorReporting = errorReporting;
    localStorage.setItem('error_reporting', JSON.stringify(errorReporting));
  },
  setLoggedUser(state, user) {
    state.user = user;
  },
  setLoggedUserDetails(state, userDetails) {
    state.userDetails = userDetails;
    const shouldAffectWorkgroup = !state.currentWorkgroup && userDetails && userDetails.workgroups && userDetails.workgroups.length;
    if (shouldAffectWorkgroup) {
      state.currentWorkgroup = userDetails.workgroups[0];
    }
  },
  changeWorkgroup(state, workgroup) {
    if (state.userDetails && state.userDetails.workgroups && state.userDetails.workgroups.includes(workgroup)) {
      state.currentWorkgroup = workgroup;
    }
  },
  setLastError(state, error) {
    state.lastError = error;
  },
  setForgotPasswordMode(state, mode) {
    state.forgotPasswordMode = mode;
  },
  setSeeAsUser(state, user) {
    state.seeAsUser = user;
    if (!Api.axios.defaults.params) {
      Api.axios.defaults.params = {};
    }
    if (user) {
      Api.axios.defaults.params.seeAs = user.username;
    } else {
      delete Api.axios.defaults.params.seeAs;
    }
  },
};

export const actions = {
  setLastError({ commit }, error) {
    commit('setLastError', error);
  },
  setModalVisible({ commit }, visible) {
    commit('setModalVisible', visible);
  },
  async loadUser({ commit, dispatch, state }) {
    console.log('LOADUSER router', getTokenFromHash());
    const tokenFromHash = getTokenFromHash();
    let user;
    if (tokenFromHash) {
      user = {
        token: tokenFromHash,
        username: 'Token',
      }
    } else {
      user = localStorage.getItem('user');
      if (user) {
        user = JSON.parse(user);
      }
    }
    if (user) {
      Api.axios.defaults.headers.common.authorization = `Token ${tokenFromHash || user.token}`;

      const result = await PermissionManager.setUser(user, state);
      integrateChatIfNeeded();
      if (result && result.success !== true) {
        localStorage.clear();
        window.location = '#/login';
        // dispatch('errors/requestError', error, { root: true });
      }

      commit('setLoggedUser', user);
      if (process.env.VUE_APP_HAS_COLLABORATING_MODE) {
        window.$commit = commit;
        window.$token = state.user.token;
        socket.connect(state.user.token, commit);
      }
      commit('setLoggedUserDetails', result.userDetails);
    } else if (state.config.showModalWhenLoggedOut) {
      dispatch('setModalVisible', true);
    }
    return user;
  },
  login({ commit, dispatch, state }, user) {
    commit('setLoginLoading', true);
    return Api.post('/login', user).then(async (response) => {
      Api.axios.defaults.headers.common.authorization = `Token ${response.data.user.token}`;
      const result = await PermissionManager.setUser(response.data.user);
      if (result && result.success !== true) {
        console.log('requestError', result);
        dispatch('errors/requestError', result, { root: true });
      }

      commit('setLoggedUserDetails', result.userDetails);

      const permissionManagerResult = await PermissionManager.setUser(response.data.user, state);
      integrateChatIfNeeded();
      if (permissionManagerResult && permissionManagerResult.success !== true) {
        localStorage.clear();
        window.location = '#/login';
        // dispatch('errors/requestError', error, { root: true });
      }

      commit('setLoggedUser', response.data.user);
      socket.connect(state.user.token, commit);
      commit('setLoginLoading', false);
      commit('setModalVisible', false);
      localStorage.setItem('user', JSON.stringify(response.data.user));
    }).catch((error) => {
      // dispatch('errors/reequestError', { message: 'Cannot fetch user', error }, { root: true });

      console.error('store login error', error, error.response);
      commit('setLoginLoading', false);
      commit('setLastError', (error.response && error.response.data) ? error.response.data.error : error.toString());
    });
  },
  logout({ commit }) {
    Api.axios.defaults.headers.common.authorization = '';
    socket.disconnect();
    commit('setLoggedUser', undefined);
    localStorage.removeItem('user');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
