<template>
  <div class="columns">
    <div class="column">
      <prototype-pins>
        <BlockEditorDemoComponent ref="be1" :value="model" :showModals="false" />
        <prototype-pin label="1" x="40px" y="30px">
           Icône de la vue
        </prototype-pin>
        <prototype-pin label="2" x="126px" y="30px">
           Titre de la vue
        </prototype-pin>
        <prototype-pin label="3" x="40px" y="180px">
           Étendre la vue pour que les données présentées prennent tout l'espace disponible sur l'écran
        </prototype-pin>
        <prototype-pin label="4" x="36px" y="230px">
          Url d'une image qui sera affichée en filigrane en arrière-plan de la vue.
        </prototype-pin>
        <prototype-pin label="5" x="36px" y="304px">
          La catégorie de la vue sert à ordonner la vue dans le menu latéral. Le nom de la catégorie servira à grouper la vue dans l'arborescence.
        </prototype-pin>
        <prototype-pin label="6" x="36px" y="382px">
          Le champ "groupe" sert à une configuration fine des droits d'accès. Il est possible de
          donner aux utilisateurs des droits spécifiques sur un groupe donné, quel que soit la
          collection des documents.
        </prototype-pin>
        <prototype-pin label="7" x="36px" y="460px">
           Ce champ permet de configurer le jeu de données à récupérer de la base de données. Vous
           pouvez assigner une même collection à plusieurs vues si vous souhaitez afficher le même
           jeu de données de manière différente, ou si vous souhaitez filtrer ces données de manière
           différente selon les vues.
        </prototype-pin>
        <prototype-pin label="8" x="36px" y="536px">
           Environnement auquel appartient la vue.
        </prototype-pin>
        <prototype-pin label="9" x="36px" y="616px">
           Filtre par défaut à affecter à la vue. Ce filtre est un filtre mongodb, qui permettra de
           n'afficher que des enregistrements répondant à certains critères.
        </prototype-pin>
      </prototype-pins>
    </div>
    <div class="column">
      <prototype-pins>
        <BlockEditorDemoComponent ref="be2" :value="model" :showModals="false" />
        <prototype-pin label="10" x="34px" y="30px">
          Schéma utilisé pour gérer les champs des enregistrements.<br/>
          Voir la partie "04 - Edition de schémas".
        </prototype-pin>
      </prototype-pins>
    </div>
    <div class="column">
      <prototype-pins>
        <BlockEditorDemoComponent ref="be3" :value="model" :showModals="false" />
        <prototype-pin label="11" x="40px" y="30px">
           Ce champ permet de changer la façon dont vous voulez représenter les données. Vous pouvez
           afficher les données dans une liste, un kanban, un calendrier...
        </prototype-pin>
        <prototype-pin label="12" x="200px" y="140px">
          Ici apparaitront les options spécifiques au widget utilisé. <br/>Par exemple, pour un kanban, il vous sera
           demandé quelle propriété vous voulez afficher en entête de colonne.
        </prototype-pin>
      </prototype-pins>
    </div>
    <div class="column">
      <prototype-pins>
        <BlockEditorDemoComponent ref="be4" :value="model" :showModals="false" />
        <prototype-pin label="13" x="40px" y="30px">
           Code JSON de la vue. Vous pouvez utiliser cet onglet si vous souhaitez modifier les
           options des autres onglets ou si vous souhaitez éditer une propriété de la vue qui
           n'apparait pas dans les formulaires présentés ci-contre.
        </prototype-pin>
      </prototype-pins>
    </div>
  </div>
</template>
<script>
const BlockEditorDemoComponent = () => ({
  component: import('../../components/BlockEditor.vue'),
  timeout: 3000
});

export default {
  components: {
    BlockEditorDemoComponent
  },
  data() {
    return {
      model: {
        title: 'Issues',
        collection: 'dashy-bugs',
        workgroup: 'wg1',
        template: '    <div class="card-content" style="position: relative; padding-left: 16px;">\n      <div class="content">\n        <span style="width: 20px; display: inline-block">\n\t        {{element.id}}\n        </span>\n        <span style="width: 35px; display: inline-block">\n          <span class="tag is-success is-rounded" v-if="element.solved">\n            <i class="mdi mdi-check" />\n          </span>\n        </span>\n        <span style="position: absolute; width: 200px; display: inline-block; top: 10px; left: 80px">\n          <div class="tag is-primary" v-if="element.criticite" style="margin-top: -20px;">\n            <i class="mdi mdi-bell"/>&nbsp;\n            {{ element.criticite }}\n          </div>\n          <span class="tag is-primary" v-for="t in element.tags" style="margin-top: -20px;">\n            {{t}}\n          </span>\n        </span>\n        <span style="padding-left: 220px;"/>\n        <span class="tag is-danger" v-if="element.testable && !element.tested" style="float: right;">\n          <i class="mdi mdi-close" />\n        </span>\n        <span class="tag is-success" v-if="element.tested" style="float: right">\n          <i class="mdi mdi-check" />\n        </span>\n        {{ element.description }}</div></div>',
        schema: 'dashy-bug',
        group: 'referentiel',
        category: 'Référentiel',
        dynamicValues: {
          id: {
            type: 'max',
            property: 'id',
            add: 1,
          },
        },
        filters: [
          {
            label: 'Unsolved',
            query: '{ solved: { $ne: true } }',
          },
        ],
        description: '<div style="margin-bottom: 10px;">\n  <span class="tag" style="margin-left: 5px; width: 20px">\n  \t#\n\t</span>\n  <span class="tag" style="margin-left: 5px; ">\n  \tOK\n\t</span>\n  <span class="tag" style="margin-left: 5px; width: 210px">\n    Criticité - tags\n  </span>\n  <span class="tag" style="float: right; margin-right: 5px;">\n  \tTests\n\t</span>\n</div>\n<div style="clear: both"/>',
        fullWidth: true,
        templateComponent: {
          name: 'CardRecordTemplate',
          fields: [],
        },
      }
    };
  },
  mounted() {
    setTimeout(() => {
      console.log('be2', this.$refs.be2);
      console.log('tabs', this.$refs.be2.$refs.tabs);
      this.$refs.be2.$refs.tabs.value = 1;
      this.$refs.be3.$refs.tabs.value = 2;
      this.$refs.be4.$refs.tabs.value = 3;
    }, 1000);
  }
}
</script>
<style scoped>
.column {
  position: relative;
}
.column >>> .block-editor {
  position: static;
  overflow-y: unset;
  height: 800px;
  box-shadow: none;
}
</style>
