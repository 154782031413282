import Api from '@/core/Api';
import { ToastProgrammatic as Toast } from 'buefy';
import roles from './roles';

export default {
  // FIXME use store directly
  async setUser(user, loginState) {
    if (user) {
      let payload;
      try {
        payload = await Api.get('/userinfo');
        payload = payload.data;

        this.user = payload.documents[0];
        this.loginState = loginState;
        return { success: true, userDetails: this.user };
      } catch (error) {
        Toast.open({
          message: `Error while loading user info : ${error}`,
          type: 'is-error',
        });
        return { success: false, message: 'Cannot fetch user info', error };
      }
    } else {
      this.user = undefined;
    }
  },
  can(action) {
    if (!this.user || !this.user.roles) {
      return false;
    }
    const userRoles = this.loginState.seeAsUser ? this.loginState.seeAsUser.roles : this.user.roles;
    if (userRoles.includes(action)) {
      return true;
    }
    for (let i = 0; i < userRoles.length; i++) {
      const roleStrSplit = userRoles[i].split(':');
      if (roleStrSplit[0] === 'role') {
        if (roles[roleStrSplit[1]] && roles[roleStrSplit[1]].includes(action)) {
          return true;
        }
      }
    }

    return false;
  },
};
