import axios from 'axios';
import Vue from 'vue';

export const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL || 'http://localhost:3332',
});

export default (ctx) => {
  const $get = async (...params) => {
    const res = await axiosInstance.get(...params);
    return res.data;
  };
  const $post = async (...params) => {
    const res = await axiosInstance.post(...params);
    return res.data;
  };
  ctx.$axios = {
    $get,
    get: async (...params) => await axiosInstance.get(...params),
    $post,
    $put: axiosInstance.put,
    $delete: axiosInstance.delete,
    axios: axiosInstance,
    defaults: axiosInstance.defaults,
  };
  Vue.prototype.$axios = ctx.$axios;
};
