<template>
  <div class="week-picker">
    <button class="button reset-value-button no-print" :disabled="disabled" @click="input(undefined)">
      <i class="mdi mdi-close" />
    </button>
    <DatePicker
      v-if="!isMobile"
      v-model="valueAsDate"
      :icon="isValid ? 'calendar-today' : 'close-octagon'"
      :value-displayed="isValid ? value : `Date invalide ${value ? '(' + value + ')': ''}`"
      dropdown-class="field-week-picker"
      :disabled="disabled"
      show-week-number
      :class="isValid ? '' : 'is-danger'"
    />
    <input v-else :value="value" class="input" type="text" @input="input($event)">
  </div>
</template>
<script>
import { isMobile } from 'mobile-device-detect';

import DatePicker from './DatePicker';

function getWeek(date) {
  const onejan = new Date(date.getFullYear(), 0, 1);
  return Math.ceil((((date - onejan) / 86400000) + onejan.getDay() + 1) / 7);
}
function ISO8601_week_no(dt) {
  const tdt = new Date(dt.valueOf());
  const dayn = (dt.getDay() + 6) % 7;
  tdt.setDate(tdt.getDate() - dayn + 3);
  const firstThursday = tdt.valueOf();
  tdt.setMonth(0, 1);
  if (tdt.getDay() !== 4) {
    tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
  }
  let wn = 1 + Math.ceil((firstThursday - tdt) / 604800000);
  if (wn < 10) {
    wn = `0${wn}`;
  } else {
    wn = `${wn}`;
  }
  if (parseInt(wn) > 30 && dt.getMonth() === 0) {
    return `${(dt.getFullYear() - 1).toString()}-${wn}`;
  }
  return `${dt.getFullYear().toString()}-${wn}`;
}

function getDateOfISOWeek(w, y) {
  const simple = new Date(y, 0, 1 + (w - 1) * 7);
  const dow = simple.getDay();
  const ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
}

function getDateOfWeek(w, y) {
  const d = (1 + (w - 1) * 7);
  return new Date(y, 0, d);
}

export default {
  components: {
    DatePicker,
  },
  props: {
    value: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      isMobile,
    };
  },
  computed: {
    isValid() {
      if (this.value === undefined || this.value === null || this.value === '') {
        return true;
      }
      return this.valueAsDate ? this.valueAsDate.getTime() > 0 : false;
    },
    valueAsDate: {
      get() {
        if (this.value) {
          const vSplitted = this.value.split('-');
          if (vSplitted.length > 1) {
            return getDateOfISOWeek(vSplitted[1], vSplitted[0]);
          }
        }
        return undefined;
      },
      set(v) {
        if (v) {
          let w = ISO8601_week_no(v).toString();
          if (w.length === 1) {
            w = `0${w}`;
          }
          this.input(`${w}`);
        }
      },
    },
  },
  methods: {
    input(value) {
      this.$emit('input', value);
      console.log('input', value);
      if (value === undefined) {
        this.$emit('inputWeekBeginningDate', undefined);
        this.$emit('inputWeekEndDate', undefined);
      } else {
        const vSplitted = value.split('-');
        const weekInitDate = getDateOfISOWeek(vSplitted[1], vSplitted[0]);
        const weekEndDate = getDateOfISOWeek(parseInt(vSplitted[1]) + 1, vSplitted[0]);

        this.$emit('inputWeekBeginningDate', weekInitDate);
        this.$emit('inputWeekEndDate', weekEndDate);
      }
    },
  },
};
</script>
<style scoped>
.week-picker {
  position: relative;
}
.reset-value-button {
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 1;
  height: 32px;
  padding-top: 4px;
}
.is-danger >>> .icon.is-left {
  color: red;
}
</style>
<style>
.field-week-picker .datepicker-row:hover {
  background: rgba(200,200,200,0.66667) !important;
}
.field-week-picker .datepicker-row.contains-selected {
  background: rgba(121,87,213,0.66667) !important;
}
.field-week-picker .datepicker-row > a, .field-week-picker .datepicker-row > div {
  border-radius: 0 !important;
  background: none !important;
}
.field-week-picker .datepicker-row > a {
  color: black !important;
}
.field-week-picker .datepicker-row > div {
  color: #b5b5b5 !important;
}
.field-week-picker .datepicker-cell.is-week-number {
  background: var(--primary-color-darker);
}
</style>
