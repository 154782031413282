<template>
  <div>
    <button class="button" @click="active = true">
      <i v-if="buttonIcon" :class="`mdi mdi-${buttonIcon}`" />
      {{ buttonLabel || i18n.manage_sessions }}
    </button>
    <Modal :active="active" :width="1400" class="sessions-modal" @close="active = false">
      <template slot="header">
        <span class="modal-title">
          {{ i18n.manage_sessions }}
        </span>
      </template>
      <table class="table">
        <tr>
          <th>{{ i18n.username }}</th>
          <th>{{ i18n.since }}</th>
          <th>{{ i18n.last_time_used }}</th>
          <th>{{ i18n.actions }}</th>
        </tr>
        <tr v-for="tokenInfo in tokens" :key="tokenInfo.token">
          <td><i class="mdi mdi-account" /> {{ tokenInfo.username }}</td>
          <td>{{ new Date(tokenInfo.since).toLocaleString() }}</td>
          <td>{{ new Date(tokenInfo.lastUsed).toLocaleString() }}</td>
          <td><a @click="revokeToken(tokenInfo.token)"><i class="mdi mdi-logout" /></a></td>
        </tr>
      </table>
    </Modal>
  </div>
</template>
<script>
import Modal from '@/components/modals/Modal';
import Api from '@/core/Api';
import i18n from 'i18n/components/SessionsManager.json';

export default {
  components: {
    Modal,
  },
  props: {
    buttonLabel: {
      type: String,
      default: undefined,
    },
    buttonIcon: {
      type: String,
      default: undefined,
    },
    filterUsername: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      i18n,
      tokens: [],
      active: false,
    };
  },
  watch: {
    active(v) {
      if (v) {
        this.fetchTokens();
      }
    },
  },
  methods: {
    async revokeToken(t) {
      await Api.get(`/revokeToken/${t}`);
      this.fetchTokens();
    },
    async fetchTokens() {
      const payload = await Api.get('/listTokens');
      if (payload.data && payload.data.success) {
        if (!this.filterUsername) {
          this.tokens = payload.data.tokens;
        } else {
          this.tokens = payload.data.tokens.filter((t) => t.username === this.filterUsername);
        }
      }
    },
  },
};
</script>
