export const state = () => ({
  filters: {},
});

export const mutations = {
  saveFilter(state, { name, filter }) {
    state.filters = {
      ...state.filters,
      [name]: filter,
    };
  },
  deleteFilter(state, { name }) {
    delete state.filters[name];
    state.filters = { ...state.filters };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
