import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import axios from 'axios';
import VueAxios from 'vue-axios';
import PortalVue from 'portal-vue';
import VuePrototypePins from 'vue-prototype-pins';
import Buefy from 'buefy';
import BlockEditorDemo from '@/core/doc/BlockEditorDemo';
import ChangelogModal from '@/components/modals/ChangelogModal';
import '@/core/adaptive.css';
import 'buefy/dist/buefy.css';
import handleError from '@/core/handleError';
import Link from './components/ui/Link';
import router from './router';
import store from './store';
import '@/core/devtools';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import VueTimeago from 'vue-timeago';

import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);

import roles from '@/permissions/roles';

if (process.env.VUE_APP_ADDITIONNAL_ROLES) {
  try {
    const envRoles = JSON.parse(process.env.VUE_APP_ADDITIONNAL_ROLES);
    for(let r in envRoles) {
      roles[r] = envRoles[r];
    }
  } catch(e) {

  }
}

dayjs.extend(customParseFormat);
window.dayjs = dayjs;

Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'fr', // Default locale
  // We use `date-fns` under the hood
  // So you can use all locales from it
  locales: {
    'fr': require('date-fns/locale/fr'),
  }
})


import '../plugins.config.js';
// const plugins = process.env.VUE_APP_PLUGINS.split(',');
// for (const p of plugins) {
//   console.log('import', p);
//   import(p);
// }

if(process.env.VUE_APP_SENTRY_DSN && process.env.VUE_APP_SENTRY_ORIGIN)
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', process.env.VUE_APP_SENTRY_ORIGIN, /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });


Vue.use(Buefy);
Vue.use(VueAxios, axios);
Vue.use(PortalVue);
Vue.use(VuePrototypePins);

const RouterLink = Vue.component('router-link');
Vue.component('nuxt-link', Link);
Vue.component('BlockEditorDemo', BlockEditorDemo);
Vue.component('ChangelogModal', ChangelogModal);

Vue.config.productionTip = false;

window.$v = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

/* window.onerror = function (msg, url, line) {
  handleError({msg, url, line, column, error});
  return false;
} */
