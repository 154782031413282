<template>
  <div>
    <component :is="component" v-for="component in pluginSlot" :key="component" />
  </div>
</template>
<script>
import PluginSlotManager from '@/core/PluginSlotManager';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pluginSlot: PluginSlotManager.slot(this.name),
    };
  },
};
</script>
