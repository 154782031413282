import deepClone from '@/core/utils/deepClone';

export const state = () => ({
  currentPanel: undefined,
  currentPanelSort: undefined,
  editLocked: true,
});

export const mutations = {
  setCurrentPanel(state, currentPanel) {
    console.log('setCurrentPanel', currentPanel);
    state.currentPanel = currentPanel;
    if(JSON.stringify(currentPanel.sort) === '{}') {
      currentPanel.sort = undefined;
    }
    state.currentPanelSort = currentPanel.sort;
  },
  setCurrentPanelSort(state, sort) {
    if(JSON.stringify(sort) === '{}') {
      sort = undefined;
    }
    state.currentPanelSort = sort;
  },
  setEditLocked(state, locked) {
    state.editLocked = locked;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
};
