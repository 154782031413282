<template>
  <span
    :class="`
      tag
      ${field.successValue && value === field.successValue ? 'is-success': ''}
      ${field.warningValue && value === field.warningValue ? 'is-warning': ''}
      ${field.dangerValue && value === field.dangerValue ? 'is-danger': ''}`">
    <i v-if="field.icon" :class="`mdi mdi-${field.icon}`" />
    {{value}}
  </span>
</template>
<script>
export default {
  name: 'RenderComponentTag',
  simpleName: 'tag',
  needField: true,
  props: {
    value: {
      type: String,
      default: undefined,
    },
    field: {
      type: Object,
      default: undefined,
    },
  },

  options: {
    fields: [{
      model: 'icon',
      label: 'icon',
      type: 'iconSelector',
    }, {
      label: 'Success Value',
      model: 'successValue',
      type: 'text',
    }, {
      label: 'Warning Value',
      model: 'warningValue',
      type: 'text',
    }, {
      label: 'Danger Value',
      model: 'dangerValue',
      type: 'text',
    }],
  },
};
</script>
