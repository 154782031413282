<template>
  <span class="panel-filters">
    <b-dropdown aria-role="list" position="is-bottom-left">
      <a class="dropdown-trigger" slot="trigger">
        <i class="mdi mdi-eye" /> &nbsp;
        <span class="hover-effect" />
        <span>{{activeFilter && JSON.stringify(activeFilter) !== '{}' ? activeFilter.label : i18n.everything}}</span>
        <i class="mdi mdi-menu-down" />
      </a>
      <b-dropdown-item
        :class="`${!activeFilter || !activeFilter.label ? 'is-active' : ''}`"
        aria-role="listitem"
        @click="$emit('filterElements', {})"
      >
        {{i18n.everything}}
      </b-dropdown-item>
      <b-dropdown-item
        v-for="f in filters" :key="f.label"
        aria-role="listitem"
        :class="`${activeFilter && activeFilter.label === f.label ? 'is-active' : ''}`"
        @click="$emit('filterElements', f)"
      >
        {{f.label}}
      </b-dropdown-item>
    </b-dropdown>
    <!--
    <div v-if="filters" class="field filters has-addons" style="display: inline-flex">
      <p class="control">
        <button :class="`button ${activeFilter.label === undefined ? 'is-success' : ''}`" @click="$emit('filterElements', {})">
          {{ i18n.everything }}
        </button>
      </p>
      <p v-for="f in filters" :key="f.label" class="control">
        <button :class="`button ${activeFilter.label === f.label ? 'is-success' : ''}`" @click="$emit('filterElements', f)">
          {{ f.label }}
        </button>
      </p>
    </div>
    -->
  </span>
</template>
<script>
import i18n from 'i18n/components/PanelFilters.json';
import redirect from '@/core/utils/redirect';

export default {
  name: 'PanelFilters',
  props: {
    filters: {
      type: Array,
      default: undefined,
    },
    activeFilter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { i18n };
  },
  watch: {
    activeFilter(f) {
      const label = (f && f.label !== undefined) ? f.label : 'all';
      if (JSON.stringify(this.$route.query) !== JSON.stringify({ ...this.$route.query, filter: label })) {
        redirect(this.$router, [{ query: { ...this.$route.query, filter: label } }]);
      }
    },
  },
};
</script>
<style scoped>
.dropdown-trigger {
  position: relative;
  width: 100%;
  display: block;
  text-align: center;
  color: white;
  font-weight: bold;
  padding-top: 8px;
  width: 200px;
  border-radius: 100px 0 0 100px;
  box-shadow: 0 2px 3px rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 10%);
  background: var(--primary-color);
  height: 40px;
}
</style>
