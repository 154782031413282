<template>
  <span>
    <i :class="`mdi mdi-printer`"/>
  </span>
</template>
<script>
export default {
  name: 'RenderComponentPrintbutton',
  simpleName: 'printbutton',
};
</script>
