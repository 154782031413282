<template>
  <div>
    <div class="toolbar-wrapper no-print" style="display: flex;">
      <div class="top-view-buttons">
        <portal-target name="top-view-buttons" />
      </div>
      <div class="field">
        <PluginSlot name="toolbar" />
      </div>
    </div>
  </div>
</template>
<script>
import PermissionManager from '@/permissions/PermissionManager';
import actions from '@/permissions/actions';
import PluginSlot from '@/components/PluginSlot';

export default {
  components: {
    PluginSlot,
  },

  data() {
    return {
      PermissionManager,
      actions,
    };
  },
};
</script>
<style scoped>
/*.toolbar-wrapper {
  width: 100% !important;
  padding-left: 6px;
  margin-right: 0;
  display: flex;
  /* because dropdown with append-to-body are always displayed in front of the toolbar even when hidden * /
  position: absolute;
  z-index: 1;
}*/
.view-title {
  margin-top: 6px;
  padding-left: 12px;
  font-size: 18px;
}
.top-view-buttons, .top-view-buttons >>> a.top-view-button {
  flex-grow: 1;
  color: #888;
}
.top-view-buttons >>> a.top-view-button:hover {
  color: var(--primary-color);
}
</style>
