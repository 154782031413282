import Api from '@/core/Api';

export const state = () => ({
  favorites: {},
});

export const actions = {
  async fetch({ commit, rootState }) {
    if (rootState.login.user !== undefined) {
      try {
        const payload = await Api.get('/system_favorites');
        for (let i = 0; i < payload.data.documents.length; i++) {
          const collectionDoc = payload.data.documents[i];
          commit('setFavorites', { collection: collectionDoc.collection, favorites: collectionDoc.favorites });
        }
      } catch (e) {
        console.error('error while fetching favorites', e);
      }
    }
  },
  async addToFavorites({ commit }, { collection, id }) {
    const payload = await Api.put(`/system_favorites/${collection}/${id}`);
    if (payload.data.success) {
      commit('setFavorites', { collection, favorites: payload.data.document.favorites });
    }
  },
  async removeFromFavorites({ commit }, { collection, id }) {
    const payload = await Api.delete(`/system_favorites/${collection}/${id}`);
    if (payload.data.success) {
      commit('setFavorites', { collection, favorites: payload.data.document.favorites });
    }
  },
};
export const mutations = {
  setFavorites(state, { collection, favorites }) {
    state.favorites[collection] = favorites;
    state.favorites = { ...state.favorites };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
