<template>
  <div id="app" :class="appClass">
    <div :class="`general-status-message ${statusMessage.type} no-print`" v-if="statusMessage" style="z-index: 1000000000000; position: fixed; top: 0; width: 100%; left: 0; height: auto;">
      <b v-if="statusMessage.title">{{statusMessage.title}} : </b>
      {{statusMessage.text}}
      <b-tooltip v-if="statusMessage.moreInfo" :label="statusMessage.moreInfo" position="is-bottom" multilined :type="statusMessage.type">
        <a class="close-status-message-button">
          <i class="mdi mdi-help" />
        </a>
      </b-tooltip>
      <a class="close-status-message-button" style="float: right" @click="dismissStatusMessage(statusMessage)">
        <i class="mdi mdi-close" />
      </a>
      <span class="glowEffect" v-if="statusMessage.pulsing"/>
    </div>
    <div v-if="routeLoaded">
      <DefaultLayout v-if="$route.name !== 'Login'" ref="layout">
        <div v-if="user && routeLoaded">
          <router-view/>
        </div>
      </DefaultLayout>
      <div v-else>
        <router-view />
      </div>
      <div v-if="displayBuildInfo" class="version-info" @click="showVersionInfo = !showVersionInfo">
        <span v-if="showVersionInfo">({{ gitinfo.git.branch }}) {{new Date(gitinfo.git.commit.time).toJSON()}} - {{gitinfo.git.commit.message.full}}</span>
        <span v-else>Show version info</span>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import DefaultLayout from '@/layouts/default.vue';
import { mapState } from 'vuex';
import Vue from 'vue';
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import gitinfo from '@/../git.properties.json';

import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import Api from '@/core/Api';
import redirect from '@/core/utils/redirect';
import getTokenFromHash from '@/core/utils/getTokenFromHash';

import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
Vue.use(Donut);

Vue.use(PerfectScrollbar);
Vue.use(Buefy);

export default {
  components: {
    DefaultLayout,
  },
  data() {
    window.$store = this.$store;
    if (window.statusMessagesInterval) {
      clearInterval(window.statusMessagesInterval);
    }
    this.$store.dispatch('login/loadUser').then((user) => {
      if (!this.isUserTokenFound && this.$route.name !== 'Login') {
        redirect(this.$router, [`/login?redirectFrom=${this.$route.query.redirectFrom || window.location.href.split('#')[1]}`]);
      } else if (this.$route.name === 'Login' && user) {
        redirect(this.$router, ['/']);
      }
      this.routeLoaded = true;
    });
    if (!process.env.VUE_APP_DISABLE_SYSTEM_MESSAGE_POLLING) {
      window.statusMessagesInterval = setInterval(() => {
        Api.get('/system_status_messages').then((res) => {
          if (res && res.data && res.data.documents && res.data.documents.length) {
            this.$store.commit('statusMessages/setStatusMessage', res.data.documents[0]);
          }
        });
      }, 30000);
    }
    return {
      displayBuildInfo: process.env.VUE_APP_DISPLAY_BUILD_INFO,
      showVersionInfo: false,
      gitinfo,
      routeLoaded: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.login.user,
      statusMessage: (state) => state.statusMessages.statusMessage,
    }),
    appClass () {
      let res = 'app';

      const experimentalFeatures = this.$store.state.experimentalFeatures.experimentalFeatures;
      if (experimentalFeatures.avoidingVirtualKeyboardFields) {
        res += ' EF_avoidingVirtualKeyboardFields';
      }
      if (this.statusMessage) {
        res += ' has-status-message';
      }
      return res;
    },
    isUserTokenFound () {
      if (this.user && this.user.username) {
        return true;
      }
      if (getTokenFromHash()) {
        return true;
      }
      return false;
    }
  },
  watch: {
    $route() {
      if (this.routeLoaded) {
        if (!this.isUserTokenFound && this.$route.name !== 'Login') {
          redirect(this.$router, [`/login?redirectFrom=${this.$route.query.redirectFrom || window.location.href.split('#')[1]}`]);
        }
      }
    },
  },
  methods: {
    dismissStatusMessage(msg) {
      this.$store.commit('statusMessages/dismissStatusMessage', undefined);
    },
  },
};
</script>
<style>
.version-info {
  position: absolute;
  bottom:10px;
  height: 20px;
  background: whitesmoke;
  right: 0;
}
.version-info span {
  margin: 10px;
}
</style>
<style scoped>
.general-status-message {
  width: 100%;
  background: whitesmoke;
  height: 40px;
  position: fixed;
  top: 0;
  left: 0;
  padding: 8px;
  box-shadow: 0 2px 3px rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 10%);
  z-index: 41;
}
.glowEffect {
  transform: scale(1);
  animation: pulse-black 2s infinite;
  pointer-events: none;
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}
@keyframes glow {
  from {
    background: #00000000;
  }
  to {
    background: #00000030;
  }
}
@keyframes pulse-black {
  0% {
    background: #ffffff40;
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    background: #ffffff00;
    transform: scale(1);
    box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.general-status-message.is-success {
  color: white;
  background: #278247;
}

.general-status-message.is-danger {
  color: #feecf0;
  background: #cc0f35;
}
.close-status-message-button {
  background: #33333350;
  border-radius: 40px;
  height: 24px;
  width: 24px;
  color: white;
  display: inline-block;
  text-align: center;
}
</style>
