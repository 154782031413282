<template>
  <div>
    <a class="button" @click="active = true">
      Derniers changements
    </a>
    <Modal :active="active" @close="active = false">
      <template slot="header">
        Changelog
      </template>
      <div style="padding: 20px">
        <pre>{{changelog}}</pre>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from '@/components/modals/Modal';
import changelog from '@/../CHANGELOG.md';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      active: false,
      changelog,
    };
  },
};
</script>
