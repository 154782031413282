<template>
  <button class="button" @click="edit">
    <i class="mdi mdi-pencil" /> {{ i18n.edit }}
  </button>
</template>
<script>
import i18n from 'i18n/components/EditButton.json';
import redirect from '@/core/utils/redirect';

export default {
  name: 'EditButton',
  props: {
    record: {
      type: Object,
      required: true,
    },
    schema: {
      type: String,
      default: undefined,
    },
    collection: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return { i18n };
  },
  methods: {
    edit() {
      console.log('edit');
      redirect(this.$router, [{ query: { ...this.$route.query, editId: this.record._id } }]);
    },
  },
};
</script>
