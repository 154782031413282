let experimentalFeatures = {};
const modelAsString = localStorage.getItem('experimentalFeatures');
if(modelAsString)
  experimentalFeatures = JSON.parse(modelAsString);

export const state = () => ({
  experimentalFeatures,
});

export const mutations = {
  save(state, o) {
    state.experimentalFeatures = o;
    localStorage.setItem('experimentalFeatures', JSON.stringify(o));
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
