<template>
  <i :class="`mdi mdi-${value || 'help-circle'}`"/>
</template>
<script>
export default {
  name: 'RenderComponentIcon',
  simpleName: 'icon',
  needField: true,
  props: {
    value: {
      type: String,
      default: undefined,
    },
  },
};
</script>
