let fields = [
  /*{
    label: 'wysiwyg',
    name: 'fieldWysiwyg',
    var: 'FieldWysiwyg',
    category: 'Inputs',
    description: 'A custom html field',
    icon: 'xml',
  }, {
    label: 'html',
    name: 'fieldHtml',
    var: 'FieldHtml',
    category: 'Inputs',
    description: 'A custom html field',
    icon: 'xml',
    hidden: true,
  },*/ {
    label: 'documentlock',
    name: 'fieldDocumentlock',
    var: 'FieldDocumentlock',
    category: 'System',
    description: 'Locks the document edition',
    icon: 'lock',
    hidden: true,
  }, {
    label: 'imagepreview',
    name: 'fieldImagepreview',
    var: 'FieldImage',
    category: 'System',
    description: 'Displays an image',
    icon: 'image',
    specificOptions: [{
      type: 'files',
      label: 'image',
      model: 'image',
      hint: 'Image à afficher dans le champ'
    }],
    hidden: true,
  }, {
    label: 'customhtml',
    name: 'fieldCustomhtml',
    var: 'FieldCustomhtml',
    category: 'System',
    description: 'A custom html field',
    icon: 'xml',
    specificOptions: [{
      type: 'code',
      label: 'html',
      model: 'html',
      hint: "Le code HTML à afficher"
    }],
    hidden: true,
  }, {
    label: 'apiendpoint',
    name: 'fieldApiendpoint',
    var: 'FieldApiendpoint',
    category: 'System',
    icon: 'link',
    hidden: true,
 }, {
    label: 'code',
    name: 'fieldFunction',
    var: 'FieldFunction',
    category: 'System',
    description: 'A javascript function',
    icon: 'function-variant',
    hidden: true,
  }, {
    label: 'code',
    name: 'fieldCode',
    var: 'FieldCode',
    category: 'Inputs',
    description: 'A simple code editor',
    icon: 'code-braces',
    hidden: true,
  }, {
    label: 'codePortal',
    name: 'fieldCodePortal',
    var: 'FieldCodePortal',
    category: 'Code',
    description: 'A simple code editor',
    icon: 'code-braces',
    hidden: true,
  }, {
    label: 'color',
    name: 'fieldColor',
    var: 'FieldColor',
    category: 'Inputs',
    description: 'A color picker',
    icon: 'palette',
  }, {
    label: 'category',
    name: 'fieldCategory',
    var: 'FieldCategory',
    category: 'System',
    description: 'pick_a_view_category_with_autosuggest',
    icon: 'folder-open',
    hidden: true,
   }, {
    label: 'iconboxes',
    name: 'fieldIconboxes',
    var: 'FieldIconboxes',
    category: 'System',
    icon: 'order-bool-ascending-variant',
    hidden: true,
   }, {
    label: 'view',
    name: 'fieldView',
    var: 'FieldView',
    category: 'System',
    description: 'Sélectionnez une vue avec des suggestions',
    icon: 'table',
    hidden: true,
 }, {
    label: 'collection',
    name: 'fieldCollection',
    var: 'FieldCollection',
    category: 'System',
    description: 'pick_a_view_collection_with_autosuggest',
    icon: 'database',
    hidden: true,
  }, {
    label: 'group',
    name: 'fieldGroup',
    var: 'FieldGroup',
    category: 'System',
    description: 'pick_a_group',
    icon: 'folder-open',
    hidden: true,
  }, {
    label: 'qrcode',
    name: 'fieldQrcode',
    var: 'FieldQrcode',
    category: 'System',
    description: 'Affiche un QR code',
    icon: 'qrcode',
    specificOptions: [{
      type: 'code',
      model: 'qrcodedata',
      label: 'Qr code data',
      hint: 'Données contenues dans le QR code.'
    }],
    hidden: true,
   }, {
    label: 'simplerelation',
    name: 'fieldSimplerelation',
    var: 'FieldSimplerelation',
    category: 'Relations',
    description: 'A simple relation',
    renderComponent: 'simplerelation',
    icon: 'link-variant',
    specificOptions: [{
      type: 'view',
      model: 'view',
      label: 'vue'
    }, {
      type: 'schemaproperties',
      label: '',
      visible: (model) => model.view !== undefined,
      schemaName: (model) => {
        if (model.view) {
          const app = window.$nuxt || window.$v;
          const search = app.$store.state.abstractElements.objects.panels.objects.filter(p => p._id === model.view);
          if (search && search.length) {
            return search[0].schema
          } else {
            return undefined;
          }
        } else return undefined;
      },
      hint: 'Les propriétés de l\'élément sélectionné qui sera utilisée comme valeur stockée par le champ sous forme d\'objet',
      model: 'onlyFields',
      showRemoveButton: true,
      items: {
        type: 'text',
      },
    }, {
      type: 'radios',
      model: 'relationType',
      label: 'Type de relation',
      values: ['Simple', 'Multiple']
    }],
 }, {
    label: 'date',
    name: 'fieldDate',
    var: 'FieldDate',
    category: 'Inputs',
    description: 'A date picker',
    renderComponent: 'date',
    icon: 'calendar',
  }, {
    label: 'placeholder',
    name: 'fieldPlaceholder',
    var: 'FieldPlaceholder',
    category: 'System',
    description: 'an_empty_field',
    icon: 'selection-off',
    hidden: true,
  }, /*
  {
    label: 'protectedtext',
    name: 'fieldProtectedtext',
    var: 'FieldProtectedtext',
    category: 'Inputs',
    description: 'protected_field',
    icon: 'account-key'
  }, */
  {
    label: 'week',
    name: 'fieldWeek',
    var: 'FieldWeek',
    category: 'Inputs',
    description: 'a_week_picker',
    icon: 'calendar',
  }, {
    label: 'files',
    name: 'fieldFiles',
    var: 'FieldFiles',
    category: 'Inputs',
    description: 'a_file_picker',
    renderComponent: 'arraycount',
    icon: 'file-multiple',
    specificOptions: [{
      type: 'number',
      hint: 'Nombre de fichiers maximum',
      label: 'maxFiles',
      model: 'maxFiles',
    }],
  }, {
    label: 'text',
    name: 'fieldText',
    var: 'FieldText',
    category: 'Inputs',
    description: 'a_regular_text_input',
    icon: 'format-letter-case',
    specificOptions: [{
      type: 'array',
      label: 'Messages d\'alerte',
      hint: 'Permet d\'afficher des messages d\'alerte en dessous du champ si il a une valeur définie',
      model: 'alert_messages',
      itemContainerComponent: 'FieldArrayItemContainer',
      items: {
        type: 'object',
        schema: {
          fields: [{
            type: 'text',
            label: 'regex',
            model: 'regex',
          }, {
            type: 'object',
            label: 'Message',
            model: 'message',
            schema: {
              fields: [{
                type: 'text',
                model: 'text',
                label: 'texte',
              }, {
                type: 'text',
                model: 'class',
                label: 'classe CSS',
              }],
            },
          }],
        },
      },
    }],
  }, {
    label: 'number',
    name: 'fieldNumber',
    description: 'Un champ permettant de renseigner un nombre.',
    var: 'FieldNumber',
    category: 'Inputs',
    description: 'a_regular_number_input',
    icon: 'numeric',
  }, {
    label: 'document',
    name: 'fieldDocument',
    var: 'FieldDocument',
    category: 'Relations',
    icon: 'database-import',
    description: 'pick_a_document_or_many_from_another_collection',
    specificOptions: [{
      type: 'collection',
      model: 'relation',
      label: 'Collection',
      required: true,
      set(model, value) {
        model.relation = { type: 'hasOne', collection: value };
      },
      get(model) {
        return model.relation?.collection;
      },
      hint: 'La collection dans laquelle chercher la liste de documents. Si vous cherchez à proposer des éléments d\'une vue, vous pouvez vous référer à la propriété "collection" de cette vue (attention à bien respecter le filtre également).'
    },{
      label: 'Schema',
      model: 'schemaName',
      type: 'document',
      styleClasses: [],
      required: true,
      labelField: 'name',
      relation: {
        type: 'hasOne',
        collection: 'schemas',
      },
      onlyFieldAsString: 'name',
      hint: "OPTIONNEL. Nom du schéma. Permet de d'aider dans la suite de la configuration du champ",
    }, {
      model: 'labelField',
      label: 'labelField',
      required: true,
      help: 'text_to_use_as_label',
      type: 'text',
    }, {
      model: 'query',
      label: 'Filter',
      help: 'query_to_use_to_find_documents',
      type: 'text',
      hint: "Filtre à appliquer sur la collection à la récupération des documents."
    }, {
      model: 'howToDisplay',
      label: 'how_to_display',
      hint: 'Choisissez si vous voulez assigner à la valeur du champ une propriété du document sélectionné en tant que chaine de caractère, ou bien si vous souhaitez que la valeur du champ soit un objet contenant certaines propriétés de l\'élément sélectionné',
      type: 'radios',
      values: [{
        name: 'onlyFieldAsString',
        value: '1',
      }, {
        name: 'set of fields',
        value: '2',
      }],
    }, {
      model: 'onlyFieldAsString',
      label: '',
      visible: (model) => model.howToDisplay === '1' && model.schemaName !== undefined,
      schemaName: (model) => model.schemaName,
      hint: 'La propriété de l\'élément sélectionné qui sera utilisée comme valeur stockée par le champ sous forme de chaine de caractères',
      type: 'schemapropertyselector',
    }, {
      model: 'onlyFieldAsString',
      label: '',
      visible: (model) => model.howToDisplay === '1' && model.schemaName === undefined,
      hint: 'La propriété de l\'élément sélectionné qui sera utilisée comme valeur stockée par le champ sous forme de chaine de caractères',
      type: 'text',
    }, {
      type: 'schemaproperties',
      label: '',
      schemaName: (model) => model.schemaName,
      visible: (model) => model.howToDisplay === '2' && model.schemaName !== undefined,
      hint: 'Les propriétés de l\'élément sélectionné qui sera utilisée comme valeur stockée par le champ sous forme d\'objet',
      model: 'onlyFields',
      showRemoveButton: true,
      items: {
        type: 'text',
      },
    }, {
      type: 'array',
      label: '',
      visible: (model) => model.howToDisplay === '2' && model.schemaName === undefined,
      hint: 'Les propriétés de l\'élément sélectionné qui sera utilisée comme valeur stockée par le champ sous forme d\'objet',
      model: 'onlyFields',
      showRemoveButton: true,
      items: {
        type: 'text',
      },
    }],
  }, {
    label: 'documents',
    name: 'fieldDocuments',
    var: 'FieldDocuments',
    renderComponent: 'arraycount',
    category: 'Relations',
    icon: 'database-import',
    description: 'pick_many_documents_from_another_collection',
    specificOptions: [{
      type: 'label',
      label: 'warning',
      model: 'values',
      get: (model) => 'use_code_warning',
    }, {
      type: 'object',
      model: 'relation',
      label: 'relation',
      schema: {
        fields: [{
          model: 'type',
          label: 'type',
          type: 'select',
          values: [
            'hasOne',
          ],
        }, {
          type: 'collection',
          label: 'collection',
          model: 'collection',
        }],
      },
    }],
  }, {
    label: 'dateReadOnly',
    name: 'fieldDateReadOnly',
    description: 'display_a_date_without_the_possibility_to_modify_it',
    var: 'FieldDateReadOnly',
    category: 'Inputs',
    icon: 'calendar',
    hidden: true,
    deprecated: true,
  }, {
    label: 'permissions',
    name: 'fieldPermissions',
    var: 'FieldPermissions',
    category: 'System',
    icon: 'account-key',
    specificOptions: [{
      type: 'input',
      inputType: 'text',
      label: 'group_label',
      model: 'groupLabel',
    }],
    hidden: true,
  }, {
    label: 'undefined',
    name: 'fieldUndefined',
    icon: 'help-circle-outline',
    description: 'Ce champ sert à gérer certains cas d\'erreur ou il est impossible de récupérer le champ demandé pour la propriété. Ne pas utiliser.',
    var: 'FieldUndefined',
    category: 'System',
    hidden: true,
  }, {
    label: 'array',
    name: 'fieldArray',
    var: 'FieldArray',
    category: 'Lists',
    icon: 'code-array',
    renderComponent: 'arraycount',
    specificOptions: [{
      type: 'label',
      label: 'warning',
      model: 'values',
      get: (model) => 'use_code_warning',
    }, {
      type: 'checkbox',
      label: 'show_remove_button',
      model: 'showRemoveButton',
    }, {
      type: 'select',
      inputType: 'text',
      label: 'item_container_component',
      model: 'itemContainerComponent',
      values: [
        { id: '', name: 'Rien' },
        { id: 'FieldArrayItemContainer', name: 'Cadre simple' },
      ]

    }, {
      type: 'json5',
      label: 'items',
      model: 'items',
    }],
  }, {
    label: 'json5',
    name: 'fieldJson5',
    var: 'FieldJson5',
    category: 'System',
    icon: 'code-braces',
    hidden: true,
  }, {
    label: 'object',
    name: 'fieldObject',
    var: 'FieldObject',
    description: 'Gère une valeur de type "Objet". Affiche un sous-formulaire pour l\'édition du champ.',
    category: 'System',
    specificOptions: [{
      type: 'json5',
      label: 'schema',
      model: 'schema',
      hint: 'Code JSON du schéma a utiliser pour générer le sous-formulaire de ce champ.'
    }],
    icon: 'code-braces-box',
  }, {
    label: 'arrayItemContainer',
    name: 'FieldArrayItemContainer',
    var: 'FieldArrayItemContainer',
    category: 'System',
    hidden: true,
  }, {
    label: 'schemapropertiesselector',
    name: 'FieldSchemapropertiesselector',
    description: 'Pick within already defined properties',
    var: 'FieldSchemaproperties',
    category: 'System',
    hidden: true,
  }, {
    label: 'schemaproperties',
    name: 'FieldSchemaproperties',
    description: '(DEPRECATED: use schemapropertiesselector instead)',
    deprecated: true,
    var: 'FieldSchemaproperties',
    category: 'System',
    hidden: true,
  }, {
    label: 'schemapropertyselector',
    name: 'FieldSchemapropertyselector',
    var: 'FieldSchemapropertyselector',
    category: 'System',
    hidden: true,
  }, {
    label: 'schemapropertieseditor',
    name: 'FieldSchemapropertieseditor',
    var: 'FieldSchemapropertieseditor',
    category: 'System',
    hidden: true,
  }, {
    label: 'propertyvisibility',
    name: 'FieldPropertyvisibility',
    var: 'FieldPropertyvisibility',
    category: 'System',
    hidden: true,
  }, {
    label: 'propertyvalidator',
    name: 'FieldPropertyvalidator',
    var: 'FieldPropertyvalidator',
    category: 'System',
    hidden: true,
  }, {
    label: 'sessiontoken',
    name: 'FieldSessiontoken',
    var: 'FieldSessiontoken',
    category: 'System',
    description: 'A unique session token field',
    icon: 'account-key',
    hidden: true,
  }, {
    label: 'schemaProperty',
    name: 'FieldSchemaProperty',
    var: 'FieldSchemaProperty',
    category: 'System',
    hidden: true,
  }, {
    label: 'customSelect',
    name: 'FieldCustomSelect',
    var: 'FieldCustomSelect',
    category: 'Inputs',
    description: 'A highly configurable dropdown. Handle objects as possible proposals, and the field value is a key of the proposals.',
  }, {
    label: 'iconSelector',
    name: 'FieldIconSelector',
    icon: 'image-frame',
    description: 'Un sélécteur d\'icône.',
    var: 'FieldIconSelector',
    renderComponent: 'icon',
    category: 'System',
  }, {
    label: 'customChecklist',
    name: 'fieldCustomChecklist',
    var: 'FieldCustomChecklist',
    category: 'Lists',
    icon: 'format-list-checks',
    specificOptions: [{
      type: 'array',
      label: 'values',
      itemContainerComponent: 'FieldArrayItemContainer',
      model: 'values',
      showRemoveButton: false,
      items: {
        type: 'object',
        schema: {
          fields: [{
            type: 'input',
            inputType: 'text',
            label: 'value',
            model: 'value',
          }, {
            type: 'input',
            inputType: 'text',
            label: 'description',
            model: 'description',
          }],
        },
      },
    }, {
      type: 'checkbox',
      model: 'listBox',
      label: 'listBox',
    }],
  },
];

fields = [
  ...fields,
  {
    name: 'FieldInput',
    label: 'input',
    category: 'Inputs',
    icon: 'form-textbox',
    description: '(DEPRECATED: use "text" or "number" fields). Regular input field. Text, number, password, ...',
    deprecated: true,
    specificOptions: [{
      label: 'input_type',
      model: 'inputType',
      required: true,
      type: 'select',
      values: [
        'text',
        'password',
        'number',
      ],
    }],
    hidden: true,
  }, {
    name: 'FieldRadios',
    label: 'radios',
    category: 'Inputs',
    icon: 'list-status',
    description: 'Une liste de cases à cocher. Seulement une seule case à cocher de la liste peut être sélectionnée.',
    specificOptions: [{
      type: 'select',
      model: 'edit_values_as',
      label: 'edit values as',
      values: ['text', 'objects'],
      onChanged (model, newVal, oldVal) {
        const res = [];
        if (newVal === 'text' && oldVal === 'objects') {
          for(let v of model.values) {
            res.push(v.id);
          }
        } else if(newVal === 'objects' && oldVal === 'text') {
          for(let v of model.values) {
            res.push({ id: v, name: v});
          }
        }
        model.values = res;
      }
    }, {
      type: 'array',
      label: 'values',
      itemContainerComponent: 'FieldArrayItemContainer',
      removeElementButtonClasses: 'specific-options-array-inline-remove-button',
      itemContainerClasses: 'specific-options-array-inline-container',
      model: 'values',
      showRemoveButton: true,
      visible: (model) => model.edit_values_as !== 'objects',
      items: {
        type: 'text',
      },
    }, {
      type: 'array',
      label: 'values',
      itemContainerComponent: 'FieldArrayItemContainer',
      model: 'values',
      showRemoveButton: true,
      visible: (model) => model.edit_values_as === 'objects',
      items: {
        type: 'object',
        schema: {
          fields: [{
            type: 'text',
            model: 'id',
            label: 'value',
          }, {
            type: 'text',
            model: 'name',
            label: 'Label',
          }],
        },
      },
    }],
  }, {
    name: 'FieldLabel',
    label: 'label',
    category: 'System',
    description: 'Affiche un texte non éditable.',
    icon: 'format-color-text',
  }, {
    name: 'FieldSelect',
    label: 'select',
    description: 'Une liste déroulante.',
    category: 'Lists',
    icon: 'format-list-bulleted',
    specificOptions: [{
      type: 'array',
      label: 'values',
      itemContainerComponent: 'FieldArrayItemContainer',
      model: 'values',
      showRemoveButton: true,
      items: {
        type: 'text',
        schema: {
          fields: [{
            type: 'text',
            model: 'id',
            label: 'value',
          }, {
            type: 'text',
            model: 'name',
            label: 'Label',
          }],
        },
      },
    }],
  }, {
    name: 'FieldTextArea',
    label: 'textArea',
    category: 'Inputs',
    icon: 'form-textarea',
    description: 'Multiline text field',
    specificOptions: [{
      type: 'input',
      inputType: 'number',
      hint: 'Nombre de lignes de texte à afficher',
      model: 'rows',
      label: 'rows',
    }, {
      type: 'checkbox',
      model: 'readonly',
      hint: 'Lecture seule',
      label: 'readonly',
    }],
  }, {
    name: 'FieldCheckbox',
    label: 'checkbox',
    category: 'Inputs',
    icon: 'check-box-outline',
    renderComponent: 'boolean',
    description: 'Simple check box for boolean values',
  }, {
    name: 'FieldChecklist',
    label: 'checklist',
    category: 'Lists',
    icon: 'format-list-checks',
    specificOptions: [{
      type: 'array',
      label: 'values',
      itemContainerComponent: 'FieldArrayItemContainer',
      model: 'values',
      showRemoveButton: true,
      items: {
        type: 'text',
      },
    }, {
      type: 'checkbox',
      model: 'listBox',
      label: 'listBox',
    }],
  },
];

const specificOptions = [];
let Vue;

(async () => {
  let i18n;
  let fieldImports;
  if (typeof window !== 'undefined') {
    Vue = await import('vue');
    const FieldCollapsableItemContainer = await import('@/components/vfg/ArrayItemContainers/CollapsableItemContainer');

    Vue = Vue.default || Vue;

    Vue.component('FieldCollapsableItemContainer', FieldCollapsableItemContainer.default);
    i18n = await import('i18n/core/vfgfields.json');
    fieldImports = await import(/* webpackChunkName: "vfg-fields" */'./vfgFieldsImports');
  } else {
    i18n = global.i18n;
  }

  for (let j = 0; j < specificOptions.length; j++) {
    const option = specificOptions[j];
    option.label = i18n[option.label] || option.label;
  }
  for (let j = 0; j < fields.length; j++) {
    const currentField = fields[j];
    currentField.originalLabel = currentField.label;
    currentField.label = i18n[currentField.label] || currentField.label;
    currentField.description = i18n[currentField.description] || currentField.description;
  }

  if (typeof window !== 'undefined') {
    for (const field of fields) {
      if (field.name && !field.name.toLowerCase().startsWith('field')) {
        throw new Error(`Check this component name, it shoud start with "field"${field.name}`);
      }
      Vue.component(field.name, fieldImports.default[field.var]);
    }
    window.$f = fields;
  } else {
  }
})();

for (let k = 0; k < fields.length; k++) {
  const currentField = fields[k];
  if (currentField.specificOptions) {
    for (let k = 0; k < currentField.specificOptions.length; k++) {
      const option = currentField.specificOptions[k];
      let oldOptionVisible;
      if (option.visible) {
        oldOptionVisible = option.visible;
      }
      option.visible = (model) => {
        if (oldOptionVisible) {
          return model.type === currentField.originalLabel && oldOptionVisible(model);
        }
        return model.type === currentField.originalLabel;
      };
      if (option.styleClasses) {
        option.styleClasses.push('specific-option');
      } else {
        option.styleClasses = ['specific-option'];
      }
      specificOptions.push(option);
    }
  }
}

window.$fields = fields;

export default {
  fields,
  specificOptions,
};
