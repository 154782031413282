import oid from 'bson-objectid';
import deepClone from '@/core/utils/deepClone';
import Api from '@/core/Api';

export const state = () => ({
  addedPanel: undefined,
  editedPanel: undefined,
  addedElement: undefined,
  editedElement: undefined,
  editedElementPromise: undefined,
  editedElementPromiseResolve: undefined,
  editedElementPromiseReject: undefined,
  addedElementPanel: undefined,
  editedElementPanel: undefined,
  objects: [],
  editingView: false,
});

export const actions = {
  async fetchObjects({ commit }) {
  },
  setEditedElement({ commit, rootState }, elementConfig) {
    commit('setEditedElement', elementConfig);
    return rootState.panels.editedElementPromise;
  },
  setEditingView({ commit, rootState }, editingView) {
    commit('setEditingView', editingView);
    if (editingView && editingView._metadatas !== undefined && editingView._metadatas.wizard) {
      commit('config/setShowPanelFromTemplateModal', true, { root: true });
    }
  },
  async toggleViewStar ({ commit, rootState }, viewId) {
    const additionnalUserInfo = deepClone(rootState.login.userDetails.additionnal_info || {});
    if (!additionnalUserInfo.starredViews) {
      additionnalUserInfo.starredViews = [];
    }
    const viewSearch = additionnalUserInfo.starredViews.indexOf(viewId);
    if (viewSearch === -1) {
      additionnalUserInfo.starredViews.push(viewId);
    } else {
      additionnalUserInfo.starredViews.splice(viewId, 1);
    }
    const res = await Api.post(`/users/${rootState.login.userDetails._id}/additionnal_info`, additionnalUserInfo);
    const alreadyExistingUserDetails = rootState.login.userDetails || {};
    commit('login/setLoggedUserDetails', { ...alreadyExistingUserDetails, ...res.data.document }, { root: true });
  }
};

export const mutations = {
  setAddedPanel(state, addedPanel) {
    state.addedPanel = addedPanel;
  },
  setEditedPanel(state, editedPanel) {
    state.editedPanel = editedPanel;
    if(state.editedPanel) {
      if (typeof editedPanel.sort === 'object') {
        state.currentPanelSort = deepClone(editedPanel.sort);
      } else {
        state.currentPanelSort = editedPanel.sort;
      }
    } else {
      state.currentPanelSort = undefined;
    }
  },
  setAddedElementPanel(state, addedElementPanel) {
    state.addedElementPanel = addedElementPanel;
  },
  setEditingView(state, editingView) {
    if (editingView) {
      state.editingView = true;
      state.editedPanel = editingView;
    } else {
      state.editingView = false;
      state.editedPanel = undefined;
    }
  },
  setEditedElementPanel(state, editedElementPanel) {
    state.editedElementPanel = editedElementPanel;
  },
  setAddedElement(state, addedElement) {
    if (addedElement) {
      addedElement._id = oid();
    }
    state.addedElement = addedElement;
  },
  setEditedElement(state, elementConfig) {
    if (elementConfig) {
      const { element, panel } = elementConfig;
      state.editedElement = element;
      state.editedElementPanel = panel;
      state.editedElementPromise = new Promise((resolve, reject) => {
        state.editedElementPromiseResolve = resolve;
        state.editedElementPromiseReject = reject;
      });
    } else {
      state.editedElement = undefined;
      state.editedElementPanel = undefined;
      if (state.editedElementPromise) {
        state.editedElementPromiseReject();
      }
    }
  },
  resolveEditionPromise(state, { object }) {
    if (state.editedElementPromise) {
      state.editedElementPromiseResolve(object);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
