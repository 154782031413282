import Handlebars from 'handlebars';
import Faltu from 'faltu';
import { ToastProgrammatic as Toast } from 'buefy';
import Api from '@/core/Api';

const compileQueryPart = (queryPart, rootState, panelParams) => {
  if (queryPart) {
    try {
      if (typeof queryPart === 'string') {
        const template = Handlebars.compile(queryPart);
        queryPart = template({
          user: rootState.login.user,
          params: panelParams,
        });
      } else {
        queryPart = JSON.stringify(queryPart);
      }
    } catch (e) {
      console.error('malformed query JSON: ', e, queryPart);
      return [];
    }
  }
  return queryPart;
};

export const state = () => ({
  editedObject: undefined,
  objects: {},
});

export const actions = {
  async queryObject(context, { collection, id }) {
    const { documents, error, success } = await Api.$get(`/${collection}/${id}`);
    return { document: documents ? documents[0] : undefined, error, success };
  },

  fetchObjects({ commit, rootState }, {
    collection, destinationBucket, query, sort, aggregate, panelParams,
  }) {
    if (!destinationBucket) {
      destinationBucket = collection;
    }
    const params = {
      query: compileQueryPart(query, rootState, panelParams),
      aggregate: compileQueryPart(aggregate, rootState, panelParams),
      sort: compileQueryPart(sort, rootState, panelParams),
    };

    let storageInfo;
    try {
      storageInfo = JSON.parse(localStorage.getItem(`persistantStorage_bucket_${destinationBucket}`));
    } catch (e) {}
    let url = `/${collection}`;

    if (storageInfo) {
      console.log('todo');
      url += `/since/${storageInfo.lastFetch}`;
    }

    commit('setLoading', { collection, bucket: destinationBucket, loading: true });
    Api.$get(url, { params }).then(({ documents }) => {
      commit('setObjects', {
        collection, destinationBucket, documents, source: 'server',
      });
      commit('setLoading', { collection, bucket: destinationBucket, loading: false });
    });

    try {
      const documents = [];
      commit('setObjects', {
        collection, destinationBucket, documents, source: 'local',
      });
      return documents;
    } catch (e) {
      console.error('unexpected error', e);
      return [];
    }
  },

  async saveObject({ commit }, {
    destinationBucket, collection, object, sort,
  }) {
    if (!destinationBucket) {
      destinationBucket = collection;
    }

    let payload;
    try {
      const objectKeys = Object.keys(object);
      const keysOmmitted = {};
      for (let i = 0; i < objectKeys.length; i++) {
        if (objectKeys[i].startsWith('__')) {
          keysOmmitted[objectKeys[i]] = object[objectKeys[i]];
          delete object[objectKeys[i]];
        }
      }
      payload = await Api.post(`/${collection}/${object._id}`, object);
      object = { ...keysOmmitted, ...payload.data.document };
    } catch (e) {
      return { error: e };
    }
    commit('updateObject', {
      collection, bucket: destinationBucket, object, sort,
    });
    Toast.open({ message: 'Object saved', type: 'is-success' });

    return { payload };
  },

  async createObject({ commit }, {
    destinationBucket, collection, object, schema, sort,
  }) {
    if (!destinationBucket) {
      destinationBucket = collection;
    }
    schema = schema || {};

    let payload;
    try {
      payload = await Api.put(`/${collection}`, { object, dynamicValues: schema.dynamicValues });
      if (!payload.data.success) {
        throw new Error(payload.data.error.message);
      }
      object = payload.data.document;
    } catch (e) {
      const errorMessage = e.message || 'undefined error';
      Toast.open({ message: `Could not save object (${errorMessage})`, type: 'is-danger' });
      return;
    }
    Toast.open({ message: 'Object created', type: 'is-success' });
    commit('addObject', {
      collection, bucket: destinationBucket, object: payload.data.document, sort,
    });

    return payload;
  },

  async deleteObject({ commit }, { collection, bucket, object }) {
    const payload = await Api.delete(`/${collection}/${object._id}`);
    commit('deleteObject', { collection, bucket, object });

    Toast.open({ message: 'Object deleted', type: 'is-success' });

    return payload;
  },
};

export const mutations = {
  setEditedObject(state, object) {
    state.editedObject = object;
  },
  setLoading(state, { collection, bucket, loading }) {
    bucket = bucket || collection;

    if (state.objects[bucket] === undefined) {
      state.objects[bucket] = {};
    }
    state.objects[bucket].isLoading = loading;
  },
  addObject(state, {
    collection, bucket, object, sort,
  }) {
    bucket = bucket || collection;
    if (state.objects[bucket] === undefined) {
      state.objects[bucket] = { objects: [] };
    }
    if (state.objects[bucket].objects === undefined) {
      state.objects[bucket].objects = [];
    }
    state.objects[bucket].objects.push(object);
    if (sort) {
      state.objects[bucket].objects = new Faltu(state.objects[bucket].objects).find({}).sort(sort).get();
    }
    state.objects = { ...state.objects };
  },

  setObjects(state, {
    collection, destinationBucket, documents, source,
  }) {
    state.objects[destinationBucket] = {
      objects: documents,
      source,
    };

    state.objects = { ...state.objects };
    localStorage.setItem(
      `persistantStorage_bucket_${destinationBucket}`,
      JSON.stringify({ objects: documents, lastFetch: new Date().toJSON() }),
    );
  },

  updateObject(state, {
    collection, bucket, object, sort,
  }) {
    bucket = bucket || collection;
    state.editedObject = undefined;
    if (state.objects[bucket] && state.objects[bucket].objects) {
      state.objects[bucket].objects = state.objects[bucket].objects.map((b) => {
        if (b._id === object._id) {
          return object;
        }
        return b;
      });
      if (sort) {
        state.objects[bucket].objects = new Faltu(state.objects[bucket].objects).find({}).sort(sort).get();
      }
    }
    state.objects = { ...state.objects };
  },

  deleteObject(state, { collection, bucket, object }) {
    bucket = bucket || collection;
    for (let i = 0; i < state.objects[bucket].objects.length; i++) {
      if (state.objects[bucket].objects[i]._id === object._id) {
        state.objects[bucket].objects.splice(i, 1);
        state.objects = { ...state.objects };
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
