<template>
  <div>
    <b-loading :is-full-page="false" :active="loading" :can-cancel="false" class="loading" style="min-height: 100px;" />
    <b-message v-if="error" type="is-danger" has-icon>
      {{ error }}
      <pre style="white-space: pre-wrap;">{{ errorDetails }}</pre>
    </b-message>
    <div v-if="!loading">
      <div v-if="filters" class="field has-addons is-pulled-right">
        <p class="control">
          <button :class="`button ${activeFilter.label === undefined ? 'is-success' : ''}`" @click="filterElements({})">
            {{ i18n.everything }}
          </button>
        </p>
        <p v-for="f in filters" :key="f.label" class="control">
          <button :class="`button ${activeFilter.label === f.label ? 'is-success' : ''}`" @click="filterElements(f)">
            {{ f.label }}
          </button>
        </p>
      </div>
      <SearchField v-if="hasSearch" v-model="searchText" />
      <div v-if="relationElements">
        <div v-for="e in relationElements" :key="e._id">
          <slot :element="e">
            {{ e }}
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import sift from 'sift';
import Fuse from 'fuse.js';
import json5 from 'json5';
import { debounce } from 'vue-debounce';
import Api from '@/core/Api';

import SearchField from '@/components/ui/SearchField';
import deepClone from '@/core/utils/deepClone';
import i18n from 'i18n/components/RelationList.json';

export default {
  name: 'RelationList',
  components: {
    SearchField,
  },
  props: {
    hasSearch: {
      type: Boolean,
      default: false,
    },
    searchableKeys: {
      type: Array,
      default: undefined,
    },
    filters: {
      type: Array,
      default: undefined,
    },
    element: {
      type: Object,
      required: true,
    },
    relationField: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      i18n,
      activeFilter: {},
      searchText: '',
      allRelationElements: undefined,
      relationElements: undefined,
      error: undefined,
      errorDetails: undefined,
      loading: true,
      debouncedSearch: debounce(() => {
        if (!this.activeFilter || Object.keys(this.activeFilter).length === 0) {
          this.relationElements = deepClone(this.allRelationElements);
        } else {
          let query = {};
          if (typeof this.activeFilter.query === 'object') {
            query = this.activeFilter.query;
          } else if (typeof this.activeFilter.query === 'string') {
            query = json5.parse(this.activeFilter.query);
          }
          this.relationElements = this.allRelationElements.filter(sift(query));
        }

        const options = {
          shouldSort: true,
          // threshold: this.fuzzySearch ? undefined : 0.1,
          threshold: 0.1,
          limit: 50,
          tokenize: !!this.fuzzySearch,
          keys: this.searchableKeys || [],
        };

        if (this.searchText && this.searchText !== '') {
          const fuse = new Fuse([...this.relationElements], options);
          this.relationElements = fuse.search(this.searchText);
        }
      }, 500),
    };
  },
  watch: {
    activeFilter: 'doFilter',
    searchText: 'doFilter',
    element: {
      handler: 'fetchElements',
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async fetchElements() {
      if (!this.relationField || !this.relationField.model) {
        this.error = '<RelationList> relationField should be defined and have a model property.';
        this.errorDetails = `relationField: ${JSON.stringify(this.relationField)}`;
        return;
      }
      const relation = this.element[this.relationField.model];
      const ids = [];

      this.loading = false;
      for (let i = 0; i < relation.length; i++) {
        ids.push(relation[i]._id);
      }
      if (ids && ids.length) {
        const { collection } = this.relationField.items.relation;
        this.loading = true;
        let { sort } = this.relationField;
        let { aggregate } = this.relationField;
        let url = `/${collection}?ids=${JSON.stringify(ids)}`;
        if (sort) {
          if (typeof sort !== 'string') {
            sort = JSON.stringify(sort);
          }
          url += `&sort=${sort}`;
        }
        if (aggregate) {
          if (typeof aggregate !== 'string') {
            aggregate = JSON.stringify(aggregate);
          }
          url += `&aggregate=${aggregate}`;
        }
        try {
          const payload = await Api.get(url);
          if (payload.data && payload.data.documents) {
            this.allRelationElements = payload.data.documents;
            this.doFilter();
          }
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      }
    },
    getSchemaByName(schemaName) {
      const schemas = this.$store.state.abstractElements.objects.schemas.objects;
      for (let i = 0; i < schemas.length; i++) {
        if (schemas[i].name === schemaName) {
          return schemas[i];
        }
      }
      return undefined;
    },
    filterElements(filter) {
      this.activeFilter = filter;
    },
    searchElements(searchText) {
      this.searchText = searchText;
    },
    doFilter() {
      try {
        this.debouncedSearch();
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
