import Handlebars from 'handlebars';
import Faltu from 'faltu';
import { ToastProgrammatic as Toast } from 'buefy';
import sift from 'sift';
import json5 from 'json5';
import Fuse from 'fuse.js';
import templateVariables from '@/core/templateVariables';
import Api from '@/core/Api';
import deepClone from '@/core/utils/deepClone';

const compileQueryPart = (queryPart, rootState, panelParams, additionnalTemplateVars) => {
  if (queryPart) {
    try {
      if (typeof queryPart === 'string') {
        const template = Handlebars.compile(queryPart);
        let templateVars = templateVariables.get(rootState, panelParams);
        if (additionnalTemplateVars !== undefined) {
          templateVars = {
            ...templateVars,
            ...additionnalTemplateVars
          };
        }
        queryPart = template(templateVars);
      } else {
        queryPart = JSON.stringify(queryPart);
      }
    } catch (e) {
      console.error('malformed query JSON: ', e, queryPart);
      return '[]';
    }
  }
  return queryPart;
};

const compileQuery = (query, filter, search) => {
  console.log('compileQuery', query, filter, search);
  if (typeof query === 'string') {
    query = json5.parse(query);
  }
  let compiledFilter;
  if (filter && typeof filter.query === 'string') {
    compiledFilter = json5.parse(filter.query);
  } else {
    compiledFilter = filter ? filter.query : undefined;
  }

  let res = { $and: [] };
  if (query && Object.keys(query).length) {
    res.$and.push(query);
  }
  if (compiledFilter && Object.keys(compiledFilter).length) {
    res.$and.push(compiledFilter);
  }
  if (search && search.keys && search.keys.length && search.searchTerm && search.searchTerm !== '') {
    const searchPart = { $or: [] };
    for (let k of search.keys) {
      searchPart.$or.push({ [k]:{$regex: search.searchTerm, $options: "i"} });
      if (!isNaN(parseInt(search.searchTerm))) {
        searchPart.$or.push({ [k]: parseInt(search.searchTerm)});
      }
    }
    res.$and.push(searchPart);
  }
  //TODO search
  console.log('compileQuery search', search)
  if (res.$and.length === 0) {
    res = {};
  } else if (res.$and.length === 1) {
    res = res.$and[0];
  }
  console.log('compileQuery res', query);
  return res;
}

export const state = () => ({
  editedObject: undefined,
  objects: {},
});

const fetchObjects = async ({
  rootState, bucket, panelParams, additionnalTemplateVars, everything
}) => {
  const collection = rootState.abstractElementsPaginated.objects[bucket].collection;
  const page = rootState.abstractElementsPaginated.objects[bucket].page;
  const perPage = rootState.abstractElementsPaginated.objects[bucket].perPage;
  let query = rootState.abstractElementsPaginated.objects[bucket].query;
  const filter = rootState.abstractElementsPaginated.objects[bucket].filter;
  const search = rootState.abstractElementsPaginated.objects[bucket].search;
  const aggregate = rootState.abstractElementsPaginated.objects[bucket].aggregate;
  const sort = rootState.abstractElementsPaginated.objects[bucket].sort;

  const skip = (page - 1) * (perPage || 10);
  const limit = perPage || 10;
  query = compileQueryPart(query, rootState, panelParams, additionnalTemplateVars);
  let finalQuery = compileQuery(query, filter, search);

  const params = {
    query: finalQuery,
    aggregate: compileQueryPart(aggregate, rootState, panelParams, additionnalTemplateVars),
    sort: compileQueryPart(sort, rootState, panelParams, additionnalTemplateVars),
    skip: everything ? undefined : skip,
    limit: everything ? undefined : limit,
    count: everything ? undefined : true,
  };
  try {
    const res = await Api.get(`/${collection}`, { params });
    return {
      documents: res.data.documents,
      total: res.data.total,
      finalQuery: params.finalQuery,
    }
  } catch (e) {
    if (e.response.data) {
      error = { ...e.response.data.error };
    }
    return { error };
  }
};

export const actions = {
  async setPage ({ rootState, commit }, { bucket, page, panelParams, additionnalTemplateVars }) {
    commit('setPage', { bucket, page });
    const res = await fetchObjects({
      rootState, bucket, panelParams, additionnalTemplateVars //TODO add limit, aggregate, sort, additionnalTemplateVars and collection in filterObject calls
    });
    commit('setObjects', {
      ...rootState.abstractElementsPaginated.objects[bucket],
      destinationBucket: bucket,
      documents: res.documents,
      total: res.total,
    });
  },
  async setPerPage ({ rootState, commit }, { bucket, perPage, panelParams, additionnalTemplateVars }) {
    commit('setPerPage', { bucket, perPage });
    const res = await fetchObjects({
      rootState, bucket, panelParams, additionnalTemplateVars //TODO add limit, aggregate, sort, additionnalTemplateVars and collection in filterObject calls
    });
    commit('setObjects', {
      ...rootState.abstractElementsPaginated.objects[bucket],
      destinationBucket: bucket,
      documents: res.documents,
      total: res.total,
    });
  },
  async filterObjects({ rootState, commit, dispatch }, {
    bucket, filter, sort, search, query, panelParams, additionnalTemplateVars //TODO add limit, aggregate, sort, additionnalTemplateVars and collection in filterObject calls
  }) {
    commit('setPage', { bucket, page: 1 });
    commit('setObjects', {
      ...rootState.abstractElementsPaginated.objects[bucket],
      destinationBucket: bucket,
      documents: [],
      filter,
      sort,
      search,
      query,
      total: 0,
    });
    const res = await fetchObjects({
      rootState, bucket, panelParams, additionnalTemplateVars //TODO add limit, aggregate, sort, additionnalTemplateVars and collection in filterObject calls
    });
    commit('setObjects', {
      ...rootState.abstractElementsPaginated.objects[bucket],
      destinationBucket: bucket,
      documents: res.documents,
      total: res.total,
    });
  },

  async queryObject({ context, rootState }, { collection, id }) {
    const payload = await Api.get(`/${collection}/${id}`);
    const { documents } = payload.data;
    const { error } = payload.data;
    const { success } = payload.data;
    return {
      document: documents ? documents[0] : undefined,
      error,
      success,
    };
  },

  async fetchObjects({ commit, rootState }, {
    collection, destinationBucket, query, sort, aggregate, limit, panelParams, additionnalTemplateVars, page, perPage, filter, search
  }) {
    if (!destinationBucket) {
      destinationBucket = collection;
    }
    perPage = perPage || 10;
    page = page || 1;
    commit('setLoading', { collection, bucket: destinationBucket, loading: true });
    commit('setObjects', {
      collection, destinationBucket, documents: [], error, source: 'server', page, perPage, rootState, filter, query, search, panelParams, total: 0, aggregate, sort,
    });
    const res = await fetchObjects({
      rootState, bucket: destinationBucket, panelParams, additionnalTemplateVars //TODO add limit, aggregate, sort, additionnalTemplateVars and collection in filterObject calls
    });
    let error;
    commit('setObjects', {
      collection, destinationBucket, documents: res.documents, error, source: 'server', page, perPage, rootState, filter, query, search, panelParams, total: res.total, aggregate, sort,
    });
    commit('setLoading', { collection, bucket: destinationBucket, loading: false });
    return res.documents;
  },

  async fetchAllObjects({ commit, rootState }, {
    collection, destinationBucket
  }) {
    if (!destinationBucket) {
      destinationBucket = collection;
    }

    commit('setLoading', { collection, bucket: destinationBucket, loading: true });
    const res = await fetchObjects({
      rootState, bucket: destinationBucket, panelParams: {}, additionnalTemplateVars: {}, everything: true //TODO add limit, aggregate, sort, additionnalTemplateVars and collection in filterObject calls
    });
    let error;
    commit('setAllObjects', {
      destinationBucket, documents: res.documents,
    });
    commit('setLoading', { collection, bucket: destinationBucket, loading: false });
    return res.documents;
  },

  async saveObject({ commit }, {
    destinationBucket, collection, object, objects, sort, offlineDocSelected, notifyLock
  }) {
    if (!destinationBucket) {
      destinationBucket = collection;
    }
    let payload;
    if (object) {
      const objectKeys = Object.keys(object);
      const keysOmmitted = {};
      for (let i = 0; i < objectKeys.length; i++) {
        if (objectKeys[i].startsWith('__')) {
          keysOmmitted[objectKeys[i]] = object[objectKeys[i]];
          delete object[objectKeys[i]];
        }
      }
      if (!offlineDocSelected) {
        try {
          let params = '';
          if (notifyLock) {
            params = `?notifyLock=${notifyLock}`;
          }
          payload = await Api.post(`/${collection}/${object._id}${params}`, object);
          if (payload.data.success && payload.data.modified === 1) {
            object = { ...keysOmmitted, ...payload.data.document };
          } else {
            Toast.open({ message: 'Erreur à l\'enregistrement', type: 'is-danger' });
            commit('statusMessages/setStatusMessage', {
              text: 'Erreur à l\'enregistrement: ' + JSON.stringify(payload.data),
              moreInfo: 'Il peut être important de faire suivre ce message à l\'administrateur systeme',
              type: 'is-danger',
            }, { root: true });
            return { error: e };
          }
        } catch (e) {
          return { error: e };
        }
        commit('updateObject', {
          collection, bucket: destinationBucket, object, sort,
        });
      } else {
        console.log('TODO document update when offline');
      }

      Toast.open({ message: 'Document sauvegardé', type: 'is-success' });

      return { payload };
    } if (objects && objects.length) {
      const keysOmmitted = {};
      objects = objects.map((o) => {
        const objectKeys = Object.keys(o);
        keysOmmitted[o._id] = {};
        for (let i = 0; i < objectKeys.length; i++) {
          if (objectKeys[i].startsWith('__')) {
            keysOmmitted[o._id][objectKeys[i]] = o[objectKeys[i]];
            delete o[objectKeys[i]];
          }
        }
        return o;
      });
      payload = await Api.post(`/${collection}`, objects);
      return { payload, data: payload.data, status: payload.status };
    }
  },

  async createObject({ commit, rootState }, {
    destinationBucket, collection, object, objects, schema, sort, offlineDocSelected, notifyLock
  }) {
    if (!destinationBucket) {
      destinationBucket = collection;
    }
    schema = schema || {};

    let payload;
    try {
      if (schema) {
        if (object !== undefined) {
          if (!object._metadatas) {
            object._metadatas = {};
          }
          object._metadatas.schemaName = schema.name;
        }
        if (objects && objects.length) {
          for (let i = 0; i < objects.length; i++) {
            if (!objects[i]._metadatas) {
              objects[i]._metadatas = {};
            }
            objects[i]._metadatas.schemaName = schema.name;
          }
        }
      }
      if (!offlineDocSelected) {
        payload = await Api.put(`/${collection}`, { object, objects, dynamicValues: schema.dynamicValues, notifyLock });

        if (!payload.data.success) {
          return payload;
        }
        object = payload.data.document;
      } else {
        if (!offlineDocSelected.additionnalSyncSteps) {
          offlineDocSelected.additionnalSyncSteps = [];
        }
        object.__tempId = `tempId_${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`;
        offlineDocSelected.additionnalSyncSteps.push({
          type: 'create',
          collection,
          object,
          objects,
          dynamicValues: schema.dynamicValues,
        });
      }
    } catch (e) {
      const errorMessage = e.message || 'undefined error';
      Toast.open({ message: `Could not save object (${errorMessage})`, type: 'is-danger' });
      return e.response;
    }
    Toast.open({ message: 'Document créé', type: 'is-success' });
    if (!offlineDocSelected) {
      if (payload && payload.data && payload.data.documents) {
        for (let i = 0; i < payload.data.documents.length; i++) {
          commit('addObject', {
            collection, bucket: destinationBucket, object: payload.data.documents[i], sort,
          });
        }
      } else {
        commit('addObject', {
          collection, bucket: destinationBucket, object: payload.data.document, sort,
        });
      }
      return payload;
    }
    return {
      data: {
        document: object,
      },
    };
  },

  async deleteObject({ commit }, { collection, bucket, object }) {
    const payload = await Api.delete(`/${collection}/${object._id}`);
    commit('deleteObject', { collection, bucket, object });

    Toast.open({
      message: 'Document supprimé',
      type: 'is-success',
    });

    return payload;
  },
};

export const mutations = {
  setPage(state, { bucket, page }) {
    state.objects[bucket].page = page;
  },
  setPerPage(state, { bucket, perPage}) {
    state.objects[bucket].perPage = perPage;
  },
  setEditedObject(state, object) {
    state.editedObject = object;
  },
  setLoading(state, { collection, bucket, loading }) {
    bucket = bucket || collection;

    if (state.objects[bucket] === undefined) {
      state.objects[bucket] = {};
    }
    state.objects[bucket].isLoading = loading;
  },
  addObject(state, {
    collection, bucket, object, sort,
  }) {
    bucket = bucket || collection;
    if (state.objects[bucket] === undefined) {
      state.objects[bucket] = { objects: [] };
    }
    if (state.objects[bucket].objects === undefined) {
      state.objects[bucket].objects = [];
    }
    state.objects[bucket].objects.push(object);
    if (sort) {
      state.objects[bucket].objects = new Faltu(state.objects[bucket].objects).find({}).sort(sort).get();
    }
    state.objects = deepClone(state.objects);
  },

  setAllObjects(state, {
    destinationBucket, documents
  }) {
    state.objects[destinationBucket].objects = documents;
  },

  setObjects(state, {
    collection, destinationBucket, documents, error, source, page, perPage, rootState, filter, query, search, aggregate, sort, panelParams, total
  }) {
    console.log('=> AEP setObjects', destinationBucket, perPage);
    state.objects[destinationBucket] = {
      page,
      collection,
      perPage,
      filter,
      query,
      search,
      filteredObjects: documents,
      paginatedFilteredElements: documents,
      aggregate,
      sort,
      error,
      source,
      total,
      isLoading: false,
    };
    state.objects = { ...state.objects };
  },

  setFilteredObjects(state, {
    bucket, documents, filter, searchTerm, total
  }) {
    if (state.objects[bucket]) {
      console.log('setFilteredObjects', filter, searchTerm, total);
      state.objects[bucket].filteredObjects = documents;
      state.objects[bucket].paginatedFilteredElements = documents;
      state.objects[bucket].filter = filter;
      state.objects[bucket].searchTerm = searchTerm;
      state.objects[bucket].total = total;
      // state.objects = { ...state.objects };
    }
  },

  updateObject(state, {
    collection, bucket, object, sort,
  }) {
    bucket = bucket || collection;
    state.editedObject = undefined;
    if (state.objects[bucket]) {
      if (state.objects[bucket].objects && state.objects[bucket].objects.map) {
        state.objects[bucket].objects = state.objects[bucket].objects.map((b) => {
          if (b._id === object._id) {
            return object;
          }
          return b;
        });
      }
      if (state.objects[bucket].filteredObjects && state.objects[bucket].filteredObjects.map) {
        state.objects[bucket].filteredObjects = state.objects[bucket].filteredObjects.map((b) => {
          if (b._id === object._id) {
            return object;
          }
          return b;
        });
      }
      if (state.objects[bucket].paginatedFilteredElements && state.objects[bucket].paginatedFilteredElements.map) {
        state.objects[bucket].paginatedFilteredElements = state.objects[bucket].paginatedFilteredElements.map((b) => {
          if (b._id === object._id) {
            return object;
          }
          return b;
        });
      }
    }
  },

  deleteObject(state, { collection, bucket, object }) {
    bucket = bucket || collection;
    let objectsModified = false;
    for (let i = 0; i < state.objects[bucket].objects.length; i++) {
      if (state.objects[bucket].objects[i]._id === object._id) {
        state.objects[bucket].objects.splice(i, 1);
        objectsModified = true;
      }
    }
    if (state.objects[bucket].filteredObjects) {
      for (let i = 0; i < state.objects[bucket].filteredObjects.length; i++) {
        if (state.objects[bucket].filteredObjects[i]._id === object._id) {
          state.objects[bucket].filteredObjects.splice(i, 1);
          objectsModified = true;
        }
      }
    }
    if (objectsModified) {
      state.objects = { ...state.objects };
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
