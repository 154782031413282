function getLastWeek() {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
}

function getPreviousMonday(d) {
  let date = new Date();
  if (d) {
    date = new Date(d);
  }
  const day = date.getDay();
  let prevMonday;
  if (date.getDay() === 0) {
    prevMonday = new Date().setDate(date.getDate() - 7);
  } else {
    prevMonday = new Date().setDate(date.getDate() - day);
  }
  return new Date(prevMonday);
}

export default {
  get(rootState, panelParams) {
    const lastWeek = getLastWeek();
    return {
      user: rootState.login.user,
      params: panelParams,
      date: {
        now: new Date().toJSON(),
        lastWeek: lastWeek.toJSON(),
        lastMonday: getPreviousMonday().toJSON(),
        lastWeekMonday: getPreviousMonday(lastWeek).toJSON(),
      },
    };
  },
};
