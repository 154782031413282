export const state = () => ({
  listPagination: 10,
});

export const mutations = {
  saveListPagination(state, perPage) {
    state.listPagination = perPage;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
