export default {
  can_see_editmodal_code: 'action:can_see_editmodal_code',
  can_edit_schemas: 'action:can_edit_schemas',
  can_see_editmodal_metadata: 'action:can_see_editmodal_metadata',
  can_see_editmodal_history: 'action:can_see_editmodal_history',
  can_see_add_panel_buttons: 'action:can_see_add_panel_buttons',
  can_see_app_settings: 'action:can_see_app_settings',
  can_edit_panels: 'action:can_edit_panels',
  can_share_view: 'action:can_share_view',
  can_export_view: 'action:can_export_view',
  can_import_data: 'action:can_import_data',
  can_view_network_status: 'action:can_view_network_status',
  can_see_editmodal_recordpage_link: 'action:can_see_editmodal_recordpage_link',
  can_see_editmodal_delete_button: 'action:can_see_editmodal_delete_button',
  can_generate_data_import_file: 'action:can_generate_data_import_file',
  can_see_support_chat: 'action:can_see_support_chat',
  table_view_mode: 'action:table_view_mode',
  can_see_see_as_mode: 'action:can_see_see_as_mode',
};
