import { ToastProgrammatic as Toast } from 'buefy';

export const state = () => ({
  errors: [],
});

export const actions = {
  requestError({ commit }, error) {
    console.log('requestError', error, arguments);
    error.type = 'request';
    if (error.message === 'Unauthorized' && error.status === 500) {
      // probably disconnected, redirect to login page
      window.location = '#/login';
    } else {
      commit('addError', error);
    }
  },
  processingError({ commit }, error) {
    Toast.open({
      message: error.message,
      type: 'is-danger',
    });
    error.type = 'processing';

    console.error(error);
    commit('addError', error);
  },
  scriptError({ commit, rootState }, error) {
    console.log('error', error);
    Toast.open({
      message: error.message,
      type: 'is-danger',
    });
    error.type = 'script';
    error.path = window.$nuxt.$route.path;
    if (rootState.editedElement) {
      error.editedElement = JSON.parse(JSON.stringify(rootState.editedElement));
    }
    if (rootState.addedElement) {
      error.addedElement = JSON.parse(JSON.stringify(rootState.addedElement));
    }
    if (rootState.editedElementPanel) {
      error.editedPanel = JSON.parse(JSON.stringify(rootState.editedElementPanel));
    }
    if (rootState.addedElementPanel) {
      error.addedPanel = JSON.parse(JSON.stringify(rootState.addedElementPanel));
    }
    commit('addError', error);
  },
};

export const mutations = {
  addError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors = [];
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
