export default {
  root: [
    'action:can_edit_schemas',
    'action:can_see_editmodal_code',
    'action:can_see_editmodal_metadata',
    'action:can_see_editmodal_history',
    'action:can_see_app_settings',
    'action:can_see_add_panel_buttons',
    'action:can_edit_panels',
    'action:can_share_view',
    'action:can_export_view',
    'action:can_import_data',
    'action:can_see_editmodal_delete_button',
    'action:can_generate_data_import_file',
    'action:can_see_support_chat',
    'action:table_view_mode',
    'action:can_see_see_as_mode',
  ],
  import_data: [
    'action:can_import_data',
    'collection:system_imports',
  ],
};
