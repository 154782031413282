<template>
  <div>
    <b-modal v-if="!isMobile || notResponsive" :active="active" :can-cancel="['x', 'outside']" has-modal-card @close="$emit('close')" :canCancel="canCancel">
      <div :style="`max-width: ${width}px;`" class="modal-card">
        <header v-if="$slots.header" class="modal-card-head">
          <slot name="header" />
        </header>
        <section class="modal-card-body">
          <slot />
        </section>
        <footer v-if="$slots.footer" class="modal-card-foot">
          <slot name="footer" />
        </footer>
      </div>
    </b-modal>
    <div v-else-if="active" class="mobile-modal">
      {{ $router.route }}
      <header v-if="$slots.header" class="modal-card-head">
        <slot name="header" />
      </header>
      <section class="modal-card-body">
        <slot />
      </section>
      <footer v-if="$slots.footer" class="modal-card-foot">
        <slot name="footer" />
      </footer>
      <a class="button modal-close-button is-danger" @click="$emit('close')">
        <i class="mdi mdi-close" />
      </a>
    </div>
  </div>
</template>
<script>
import { isMobile } from 'mobile-device-detect';

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 1400,
    },
    notResponsive: {
      type: Boolean,
      default: false,
    },
    canCancel: {
      type: Array,
      default: () => (['escape', 'button', 'outside']),
    }
  },
  data() {
    return {
      isMobile,
    };
  },
  watch: {
    $route(to, from) {
      this.$emit('close');
    },
  },
};
</script>
<style>

.modal-card {
  width: 1300px !important;
  margin-left: -150px !important;
}

.modal-card-body {
  position: relative;
}

@media only screen and (max-width: 1330px) {
  .modal-card {
    position: fixed !important;
    margin-left: 0px !important;
    top: 20px;
    left: 0;
    width: 100vw !important;
    height: calc(100% - 60px);
    min-height: 85vh;
  }
}
</style>
<style scoped>
.mobile-modal {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal-close-button {
  position: absolute;
  top: 6px;
  left: calc(100vw - 56px);
  z-index: 1;
}
.mobile-modal header {
  padding-right: 62px !important;
}
</style>
