import Handlebars from 'handlebars';
import sift from 'sift';
import json5 from 'json5';
import Fuse from 'fuse.js';
import Api from '@/core/Api';
import { actions as abstractElementsActions, mutations as abstractElementsMutations } from './abstractElements';

const needsToFetchEverything = (bucket, query, aggregate) => {
  if (bucket && bucket.lastFetch && bucket.lastQuery === query && bucket.lastAggregate === aggregate) {
    return false;
  }
  return true;
};

const compileQueryPart = (queryPart, rootState, panelParams) => {
  if (queryPart) {
    try {
      if (typeof queryPart === 'string') {
        const template = Handlebars.compile(queryPart);
        queryPart = template({
          user: rootState.login.user,
          params: panelParams,
        });
      } else {
        queryPart = JSON.stringify(queryPart);
      }
    } catch (e) {
      console.error('malformed query JSON: ', e, queryPart);
      return [];
    }
  }
  return queryPart;
};

export const state = () => ({
  editedObject: undefined,
  objects: {},
});

export const actions = {
  ...abstractElementsActions,
  filterObjects({ rootState, commit }, { bucket, filter, search }) {
    console.log('filterObjects', bucket, filter);
    let filteredObjects = JSON.parse(JSON.stringify(rootState.persistantStorage.objects[bucket].objects));
    if (filter && filter.query) {
      filter = filter.query;
      if (typeof filter === 'string') {
        filter = json5.parse(filter);
      }
      filteredObjects = rootState.persistantStorage.objects[bucket].objects.filter(sift(filter));
    }
    if (search) {
      const options = {
        shouldSort: true,
        threshold: 0.1,
        limit: 50,
        tokenize: !!search.fuzzySearch,
        keys: search.keys || [],
      };

      const fuse = new Fuse(filteredObjects, options);
      if (search.searchTerm && search.searchTerm !== '') {
        filteredObjects = fuse.search(search.searchTerm);
      }
    }

    commit('setFilteredObjects', { bucket, documents: filteredObjects });
  },

  async fetchObjects({ commit, rootState, dispatch }, {
    collection, destinationBucket, query, sort, aggregate, panelParams,
  }) {
    if (!destinationBucket) {
      destinationBucket = collection;
    }
    const params = {
      query: compileQueryPart(query, rootState, panelParams),
      aggregate: compileQueryPart(aggregate, rootState, panelParams),
      sort: compileQueryPart(sort, rootState, panelParams),
    };

    if (!needsToFetchEverything(rootState.persistantStorage.objects[destinationBucket], query, aggregate)) {
      console.log('FETCHOBJECTS since');
      const { lastFetch } = rootState.persistantStorage.objects[destinationBucket];
      commit('setLoading', { collection, bucket: destinationBucket, loading: true });
      const res = await Api.$get(`/${collection}/since/${lastFetch}`, { params });
      commit('setObjectsSince', {
        collection, destinationBucket, documents: res.documents, ids: res.ids, source: 'server',
      });
      commit('setLoading', { collection, bucket: destinationBucket, loading: false });
      // return res.documents;
    } else {
      console.log('FETCHOBJECTS first');
      commit('setLoading', { collection, bucket: destinationBucket, loading: true });
      const res = await Api.$get(`/${collection}`, { params });
      commit('setObjects', {
        collection, destinationBucket, documents: res.documents, source: 'server', query, aggregate,
      });
      commit('setLoading', { collection, bucket: destinationBucket, loading: false });
      return res.documents;
    }
  },
};

export const mutations = {
  ...abstractElementsMutations,
  setObjects(state, {
    collection, destinationBucket, documents, source, query, aggregate,
  }) {
    const byId = {};
    for (let i = 0; i < documents.length; i++) {
      byId[documents[i]._id] = documents[i];
    }
    state.objects[destinationBucket] = {
      objects: documents,
      filteredObjects: documents,
      source,
      byId,
      lastFetch: new Date().toJSON(),
      lastQuery: query,
      lastAggregate: aggregate,
    };

    state.objects = { ...state.objects };
  },
  setObjectsSince(state, {
    collection, destinationBucket, documents, ids, source,
  }) {
    const byId = { ...state.objects[destinationBucket].byId };
    for (let i = 0; i < documents.length; i++) {
      byId[documents[i]._id] = documents[i];
    }
    state.objects[destinationBucket].byId = byId;
    const objects = [];
    for (let j = 0; j < ids.length; j++) {
      if (byId[ids[j]] === undefined) {
        console.error('byId not found', ids[j]);
      }
      objects.push(byId[ids[j]]);
    }

    state.objects = {
      ...state.objects,
      [destinationBucket]: {
        objects,
        filteredObjects: objects,
        source,
        byId,
        lastFetch: new Date().toJSON(),
      },
    };
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
