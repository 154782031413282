<template>
  <div class="error-manager no-print">
    <div v-if="errors && errors.length" class="error-container">
      <a class="bug-icon" @click="errorsShown = true">
        <i class="mdi mdi-bug" />
      </a>
    </div>
    <Modal :active="!!errorsShown" :width="1600" @close="errorsShown = false">
      <a class="button is-primary" style="float: right; margin-left: 12px;" @click="errorsShown = false">
        <i class="mdi mdi-close" />
      </a>
      <a class="button" style="float: right" @click="$store.commit('errors/clearErrors')">
        Clear errors
      </a>
      <button class="button" :disabled="!(errors && errors.length)" style="float: right" @click="sendReport">
        Send report
      </button>
      <h1 class="title">
        {{ i18n.error_reports }}
      </h1>
      <div class="errors-content">
        <div
          v-for="(e, index) in errors"
          :key="e.error"
          class="error"
        >
          <a class="error-title" @click="toggleErrorVisibility(index)">
            <span class="tag error-tag is-danger">
              {{ i18n.error }}
            </span>
            {{ e.message }}
            <span v-if="e.path" style="font-weight: normal; opacity: 0.7">
              ({{ e.path }})
            </span>
          </a>
          <div v-if="e.shown" style="padding-left: 50px;">
            <div v-if="e.error && e.error.message">
              <h3 class="subtitle">
                {{ i18n.error_description }}
              </h3>
              <span>
                {{ e.error.message }}
              </span>
            </div>
            <div v-if="e.stack">
              <h3 class="subtitle">
                Stacktrace
              </h3>
              <pre>
                {{ e.stack }}
              </pre>
            </div>
            <div v-if="diagnosticFor(e)">
              <div class="subtitle">
                {{ i18n.error_diagnostic }}
              </div>
              <ul v-for="m in diagnosticFor(e)" :key="m">
                <li>{{ m }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Modal from '@/components/modals/Modal';
import deepClone from '@/core/utils/deepClone';
import axios from 'axios';
import { ToastProgrammatic as Toast } from 'buefy';
import i18n from 'i18n/components/ErrorManager.json';
import redirect from '@/core/utils/redirect';

export default {
  name: 'ErrorManager',
  components: {
    Modal,
  },
  data() {
    return {
      i18n,
      errors: [],
      errorsShown: false,
    };
  },
  computed: {
    ...mapState({
      errorsFromStore: (state) => state.errors.errors,
    }),
  },
  watch: {
    errorsFromStore: {
      handler(v) {
        this.errors = deepClone(v);
      },
      deep: true,
    },
  },
  methods: {
    toggleErrorVisibility(index) {
      this.errors[index] = {
        ...this.errors[index],
        shown: !this.errors[index].shown,
      };
      this.errors = [...this.errors];
    },
    gotoLogin() {
      this.$store.commit('errors/clearErrors');
      redirect(this.$router, ['/login']);
    },
    diagnosticFor(error) {
      if (error.error && error.error.message === 'Network Error') {
        return [
          this.i18n.try_to_refresh_the_page,
          this.i18n.please_ensure_that_you_are_correctly_connected_to_internet,
          this.i18n.contact_the_server_admin_to_check_that_the_server_is_running_correctly,
        ];
      }
    },
    async sendReport() {
      const reportAdress = process.env.VUE_APP_SCRIPT_ERROR_REPORT_ADRESS;
      if (reportAdress) {
        await axios.post(reportAdress, {
          type: 'error_log',
          date: new Date().toJSON(),
          username: this.$store.state.login.userDetails.username,
          errors: JSON.stringify(this.errors),
        });
        Toast.open({
          message: 'The error report has been sent. Thanks!',
          type: 'is-success',
        });
      }
    },
  },
};
</script>
<style scoped>
.error-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
}
.error-section {
  top: 100px;
  bottom: 100px;
  width: 800px;
  left: calc(50% - 400px);
  margin: auto;
  position: absolute;
  overflow: auto;
}
.error-section > div {
  position: relative;
}
.title, .subtitle {
  color: #cd0930;
}

.subtitle {
  text-decoration: underline;
  margin-bottom: 0 !important;
}

ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: circle inside;
}
.error-title {
  font-size: 16px;
  font-weight: bold;
  background-color: var(--error-color-darker);
  color: white;
  padding: 5px;
  width: 100%;
  display: block;
}
.error-tag {
  margin-top: -8px;
}
.bug-icon {
  position: fixed;
  right: 134px;
  bottom: 50px;
  font-size: 48px;
  background: var(--error-color-darker);
  border-radius: 100%;
  width: 64px;
  height: 64px;
  color: white;
  text-align: center;
}
.bug-icon i {
  display: block;
  margin-top: -3px;
}
.error {
  border-left: 10px solid var(--error-color-darker);
}
.errors-content {
  height: 500px;
  overflow: auto;
}
</style>
