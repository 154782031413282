<template>
  <div>
    <b-input
      :value="value"
      :placeholder="i18n.search"
      class="panelTitleBarSearch"
      type="search"
      icon="magnify"
      @input="$emit('input', $event)"
    />
  </div>
</template>
<script>
import i18n from 'i18n/components/ui/SearchField.json';

export default {
  name: 'SearchField',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return { i18n };
  },
};
</script>
