import axios from 'axios';

const reportError = (error, params) => {
  const app = window.$nuxt || window.$v;
  if (app.$store.state.login.errorReporting) {
    const reportAdress = process.env.VUE_APP_API_ERROR_REPORT_ADRESS;
    console.log('ERROR', process.env.VUE_APP_API_ERROR_REPORT_ADRESS);
    if (reportAdress) {
      if (error) {
        error = {
          message: error.message,
          stack: error.stack,
        };
      }
      axios.post(reportAdress, {
        type: 'ajax',
        date: new Date().toJSON(),
        username: app.$store.state.login.userDetails.username,
        error,
        params: JSON.stringify(params),
      });
    }
  }
};

const enrichParams = (params) => {
  const app = window.$nuxt || window.$v;
  if (params === undefined) {
    params = {};
  }
  if (params.params === undefined) {
    params.params = {};
  }
  if (app && app.$store.state.login.seeAsUser) {
    params.params.seeAs = app.$store.state.login.seeAsUser.username;
  }
  if (app && app.$store.state.login.currentWorkgroup) {
    params.params.workgroup = app.$store.state.login.currentWorkgroup;
  }
  return params;
};

const api = {
  axios: axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
  }),

  async get(...params) {
    params[1] = enrichParams(params[1]);
    let res;
    try {
      res = await this.axios.get(...params);
      return res;
    } catch (e) {
      reportError(e, params);
      throw e;
    }
  },
  async post(...params) {
    params[2] = enrichParams(params[2]);
    try {
      const res = await this.axios.post(...params);
      return res;
    } catch (e) {
      reportError(e, params);
      throw e;
    }
  },
  async put(...params) {
    params[2] = enrichParams(params[2]);
    try {
      const res = await this.axios.put(...params);
      return res;
    } catch (e) {
      reportError(e, params);
      throw e;
    }
  },
  async delete(...params) {
    params[1] = enrichParams(params[1]);
    try {
      const res = await this.axios.delete(...enrichParams(params));
      return res;
    } catch (e) {
      reportError(e, params);
      throw e;
    }
  },
};

window.$api = api;

export default api;
