export default () => {
  console.log('GETTOKENFROMHASH', window.location.hash);
  const query = window.location.hash.split('?')[1];
  if (query) {
    const tokenPart = query.split('&').filter(item => item.startsWith('token='))[0];
    if (tokenPart) {
      const token = tokenPart.split('=')[1];
      return token;
    }
  }
};
