<template>
  <div>
    <button class="button is-primary" @click="modalOpened = true">
      <i class="mdi mdi-plus" />
    </button>
    <Modal :class="`add-to-relation-modal ${isMobile ? 'mobile': 'desktop'}`" :active="modalOpened" :width="1600" @close="modalOpened = false">
      <template slot="header">
        {{ i18n.add_element_to_list }}
      </template>
      <b-loading :is-full-page="false" :active="resultsLoading" :can-cancel="false" />
      <div class="search-container">
        <SearchField v-model="searchText" class="search" />
      </div>
      <b-tabs v-if="allGroups && groupedItems" type="is-boxed" vertical>
        <b-tab-item v-for="g in allGroups" :key="g" :label="g">
          <table>
            <tr>
              <th v-for="f in fields" :key="f">
                {{ f }}
              </th>
            </tr>
            <tr v-for="item in groupedItems[g]" :key="item._id" :class="{ included: isIncluded(item) }" @click="clickOnItem(item)">
              <td v-for="(f, i) in fields" :key="f">
                <i v-if="i === 0 && isIncluded(item)" class="mdi mdi-check" />
                {{ item[f] }}
              </td>
            </tr>
          </table>
        </b-tab-item>
      </b-tabs>
    </Modal>
  </div>
</template>
<script>
import { isMobile } from 'mobile-device-detect';
import Fuse from 'fuse.js';
import { debounce } from 'vue-debounce';
import Modal from '@/components/modals/Modal';
import SearchField from '@/components/ui/SearchField';
import Api from '@/core/Api';
import i18n from 'i18n/components/modals/AddToRelationModal.json';

export default {
  name: 'AddToRelationModal',
  components: {
    Modal,
    SearchField,
  },
  props: {
    relationField: {
      type: Object,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
    documentCollection: {
      type: String,
      required: true,
    },
    groupKey: {
      type: String,
      required: true,
    },
    tableFields: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      i18n,
      searchText: '',
      modalOpened: false,
      resultsLoading: false,
      isMobile,
      allGroups: [],
      allItems: undefined,
      items: undefined,
      groupedItems: undefined,
      error: undefined,
      debouncedSearch: debounce(() => {
        const options = {
          shouldSort: true,
          threshold: 0.1,
          limit: 50,
          tokenize: !!this.fuzzySearch,
          keys: this.relationField.items.onlyFields || [],
        };

        if (this.searchText && this.searchText !== '') {
          const fuse = new Fuse([...this.allItems], options);
          this.items = fuse.search(this.searchText);
        } else {
          this.items = this.allItems;
        }
      }, 500),
    };
  },
  computed: {
    fields() {
      return this.tableFields || this.relationField.items.onlyFields;
    },
    relationValue() {
      return this.document[this.relationField.model];
    },
  },
  watch: {
    searchText: 'doFilter',
    modalOpened: {
      async handler(v) {
        if (!this.allItems) {
          try {
            this.resultsLoading = true;
            const { collection } = this.relationField.items.relation;
            const payload = await Api.get(`/${collection}`);
            this.resultsLoading = false;
            this.allItems = payload.data.documents;
            this.extractAllGroups();
          } catch (e) {
            this.error = e;
            this.resultsLoading = false;
          }
        }
      },
    },
    items: {
      handler(items) {
        const { groupKey } = this;
        const groupedItems = {};
        for (let i = 0; i < items.length; i++) {
          if (groupedItems[items[i][groupKey]] === undefined) {
            groupedItems[items[i][groupKey]] = [];
          }
          groupedItems[items[i][groupKey]].push(items[i]);
        }
        this.groupedItems = groupedItems;
      },
      deep: true,
    },
    allItems: {
      handler: 'doFilter',
      deep: true,
    },
  },
  methods: {
    isIncluded(doc) {
      if (this.relationValue.filter((d) => d._id === doc._id).length) {
        return true;
      }
    },
    extractAllGroups() {
      const { groupKey } = this;
      const res = [];
      for (let i = 0; i < this.allItems.length; i++) {
        if (!res.includes(this.allItems[i][groupKey])) {
          res.push(this.allItems[i][groupKey]);
        }
      }
      this.allGroups = res;
    },
    async clickOnItem(item) {
      const fieldName = this.relationField.model;
      const newDoc = { ...this.document };
      newDoc[fieldName].push(item);
      const payload = await Api.post(`/${this.documentCollection}/${newDoc._id}`, newDoc);
      console.log('payload', payload);
      this.$emit('itemSelected');
    },
    searchElements(searchText) {
      this.searchText = searchText;
    },
    doFilter() {
      try {
        this.debouncedSearch();
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
<style scoped>
.search {
  margin-left: 290px;
  margin-right: 10px;
}
tr {
  cursor: pointer;
}

tr:hover {
  background: #dedede;
}

.included, .included:hover {
  background: #7957d5;
  color: white;
}
.included td {
  border-top: 1px solid #563f94 !important;
  border-bottom: 1px solid #563f94 !important;
}
</style>
<style>
.add-to-relation-modal .tab-content, .add-to-relation-modal nav.tabs {
  height: calc(85vh - 68px);
  overflow: auto;
}
.add-to-relation-modal .tab-content {
  padding: 0;
}
.add-to-relation-modal .modal-card-body {
  overflow: hidden;
  padding: 0;
  height: 85vh;
}
.search-container {
  padding: 10px;
  padding-top: 20px;
  background: whitesmoke;
  border-bottom: 1px solid #dbdbdb;
}
</style>
