<template>
  <span :class="`boolean ${value === true ? 'is-true' : ''} ${value === false ? 'is-false' : ''}`" />
</template>
<script>
export default {
  name: 'RenderComponentBoolean',
  simpleName: 'boolean',
  needField: true,
  props: {
    value: {
      type: Boolean,
      default: undefined,
    },
  },
};
</script>
<style scoped>
.boolean {
  padding-top: 0px;
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #ccc;
  border-radius: 100%;
  margin-top: -2px;
  margin-left: 10px;
  margin-right: 10px;
}
.is-true {
  background: #48c774;
}
.is-false {
  background: #f14668;
}
</style>
