export const state = () => ({
  statusMessage: undefined,
});

export const mutations = {
  setStatusMessage(state, message) {
    if (localStorage.getItem('lastDismissedStatusMessageId') !== message._id) {
      state.statusMessage = message;
    }
  },
  dismissStatusMessage(state) {
    localStorage.setItem('lastDismissedStatusMessageId', state.statusMessage._id);
    state.statusMessage = undefined;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
