<template>
  <div class="favorites">
    <b-loading :is-full-page="false" :active="loading" :can-cancel="false" />
    <div v-if="loading === false && (!filteredDocuments || filteredDocuments.length === 0)" class="content has-text-grey has-text-centered" style="margin-top: 50px">
      <p>
        <b-icon
          icon="format-list-bulleted-type"
          size="is-large"
        />
      </p>
      <p>{{ $t('no_records') }}</p>
    </div>
    <span style="position: absolute; right: 0; top: 0;">
      <PanelFilters
        :filters="config.filters"
        :active-filter="activeFilter"
        @filterElements="filterElements($event)"
      />
    </span>
    <span style="clear: both; height: 30px;" />
    <div v-for="element in filteredDocuments" :key="element._id">
      <ListElement :element="element" :config="config" @edit="click(element)" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import sift from 'sift';
import json5 from 'json5';
import ListElement from '@/components/ListElement';
import PanelFilters from '@/components/PanelFilters';
import deepClone from '@/core/utils/deepClone';
import Api from '@/core/Api';

export default {
  components: {
    ListElement,
    PanelFilters,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      activeFilter: {},
      searchText: '',
      loading: false,
      filteredDocuments: [],
      documents: [],
      selection: [],
    };
  },
  computed: {
    ...mapState({
      favorites: (state) => state.favorites.favorites,
    }),
  },
  watch: {
    favorites: {
      async handler(f) {
        this.loading = true;
        const payload = await Api.get(`/${this.config.collection}?ids=${JSON.stringify(f[this.config.collection])}`);
        this.loading = false;
        console.log('payload favs', payload);
        this.documents = deepClone(payload.data.documents);
        this.filteredDocuments = deepClone(payload.data.documents);
      },
      immediate: true,
    },
  },
  methods: {
    toggleSelected(element) {
      if (this.selection.includes(element)) {
        this.selection.splice(this.selection.indexOf(element), 1);
      } else {
        this.selection.push(element);
      }
    },
    click(element) {
      const panels = this.$store.state.abstractElements.objects.panels.objects;
      this.$store.dispatch('panels/setEditedElement', {
        element,
        panel: {
          ...this.config,
          schema: panels.filter((p) => p.group === element.group)[0].schema,
        },
      });
    },
    filterElements(filter) {
      console.log('filterElements');
      this.searchText = '';
      this.activeFilter = filter;
      this.applyFilterAndSearch();
    },
    applyFilterAndSearch() {
      let filteredObjects = deepClone(this.documents);
      let filter = this.activeFilter;
      if (filter && filter.query) {
        filter = filter.query;
        if (typeof filter === 'string') {
          filter = json5.parse(filter);
        }
        filteredObjects = filteredObjects.filter(sift(filter));
      }
      /*
      if (search) {
        const options = {
          shouldSort: true,
          threshold: 0.1,
          limit: 50,
          tokenize: !!search.fuzzySearch,
          keys: search.keys || []
        };

        const fuse = new Fuse(filteredObjects, options);
        if (search.searchTerm && search.searchTerm !== '') {
          filteredObjects = fuse.search(search.searchTerm);
        }
      }
      */
      this.filteredDocuments = filteredObjects;
    },
  },
};
</script>
<i18n>
{
  "en": {
    "no_records": "No records."
  },
  "fr": {
    "no_records": "Pas d'enregistrements."
  }
}
</i18n>
<style scoped>
.favorites {
  padding-top: 50px;
  position: relative;
  max-width: 1000px;
  margin: auto;
}
</style>
