<template>
  <div>
    <div :class="`card file-preview ${fullscreen ? 'fullscreen': ''} ${minimized ? 'minimized': ''}`" v-if="file">
      <div class="card-header">
        <span class="card-header-title">
          <i :class="`mdi mdi-${icons[file.mimetype] || 'file-question' }`" />
          {{ file.originalname }} <span class="tag is-warning" style="margin-left: 10px; margin-right: 10px">Experimental</span>
          {{page}} / {{pdfDoc ? pdfDoc.numPages : ''}}
          <b-tooltip label="Page précédente" position="is-bottom">
            <a @click="prevPage">
              <i class="mdi mdi-chevron-left" />
            </a>
          </b-tooltip>
          <b-tooltip label="Page suivante" position="is-bottom">
            <a @click="nextPage">
              <i class="mdi mdi-chevron-right" />
            </a>
          </b-tooltip>
          <b-tooltip label="Zoom" position="is-bottom">
            <a>
              <i class="mdi mdi-magnify" />
              {{scale}}
            </a>
          </b-tooltip>
          <a @click="scale -= 0.2">
            <i class="mdi mdi-magnify-minus"/>
          </a>
          <a @click="scale += 0.2">
            <i class="mdi mdi-magnify-plus" />
          </a>
        </span>
        <b-tooltip label="Réduire" position="is-bottom">
           <a class="card-header-icon" @click="minimized = !minimized">
            <i :class="`mdi ${minimized ? 'mdi-window-maximize': 'mdi-window-minimize'}`" />
          </a>
         </b-tooltip>
         <b-tooltip label="Plein écran" position="is-bottom">
          <a class="card-header-icon" @click="fullscreen = !fullscreen">
            <i :class="`${fullscreen ? 'mdi mdi-fullscreen-exit' : 'mdi mdi-fullscreen'}`" />
          </a>
        </b-tooltip>
        <b-tooltip label="Fermer" position="is-bottom">
          <a class="card-header-icon" @click="$store.dispatch('fileViewer/setFileDisplayed', undefined)">
            <i class="mdi mdi-close" />
          </a>
        </b-tooltip>
      </div>
      <div class="card-content">
        <b-message type="is-danger" has-icon v-if="error">
          {{error}}
        </b-message>
        <b-loading :is-full-page="false" v-model="loading" />
        <canvas id="pdf-preview-canvas"/>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import axios from 'axios';

const pdfjsLib = require('@/libs/pdf');

export default {
  data() {
    return {
      icons: {
        'application/pdf': 'file-pdf',
      },
      pdfDoc: undefined,
      page: 1,
      scale: 1.5,
      pageRendering: false,
      pdfFile: undefined,
      loading: false,
      viewport: undefined,
      context: undefined,
      canvas: undefined,
      error: undefined,
      fullscreen: false,
      minimized: false,
    };
  },
  computed: {
    ...mapState({
      file: (state) => state.fileViewer.fileDisplayed,
    }),
  },
  watch: {
    fullscreen () {
      if (this.minimized) {
        this.minimized = false;
      }
    },
    minimized () {
      if (this.fullscreen) {
        this.fullscreen = false;
      }
    },
    scale() {
      this.renderPage(this.page);
    },
    file (file) {
      this.pdfDoc = undefined;
      this.error = undefined;
      this.fullscreen = false;
      this.minimized = false;
      this.page = 1;
      this.scale = 1.5;
      if(file) {
        const baseUrl = process.env.VUE_APP_BASE_URL || 'http://localhost:3332';
        this.loading = true;
        axios({
          url: `${baseUrl}/system_files/${file._id}/file`,
          method: 'GET',
          responseType: 'arraybuffer',
          headers: {
            Authorization: `Token ${this.$store.state.login.user.token}`,
          },
        }).then((response) => {
          this.loading = false;
          // The workerSrc property shall be specified.
          pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';

          // Using DocumentInitParameters object to load binary data.
          var loadingTask = pdfjsLib.getDocument({data: response.data});
          loadingTask.promise.then((pdf) => {
            this.pdfDoc = pdf;
            // Fetch the first page
            var pageNumber = 1;
            pdf.getPage(pageNumber).then((page) => {

              this.viewport = page.getViewport({scale: this.scale});

              // Prepare canvas using PDF page dimensions
              this.canvas = document.getElementById('pdf-preview-canvas');
              this.context = this.canvas.getContext('2d');
              this.canvas.height = this.viewport.height;
              this.canvas.width = this.viewport.width;

              // Render PDF page into canvas context
              var renderContext = {
                canvasContext: this.context,
                viewport: this.viewport
              };
              var renderTask = page.render(renderContext);
              renderTask.promise.then(() => {
                console.log('Page rendered');
              });
            });
          }, (reason) => {
            // PDF loading error
            this.loading = false;
            this.error = reason;
            console.error(reason);
          });
        }).catch((e) => {
          this.loading = false;
          this.fileDownloadError();
        });
      }
    }
  },
  methods: {
    renderPage(num) {
      this.pageRendering = true;
      // Using promise to fetch the page
      this.pdfDoc.getPage(num).then((page) => {
        this.viewport = page.getViewport({scale: this.scale});
        this.canvas.height = this.viewport.height;
        this.canvas.width = this.viewport.width;

        // Render PDF page into canvas context
        var renderContext = {
          canvasContext: this.context,
          viewport: this.viewport
        };
        var renderTask = page.render(renderContext);

        // Wait for rendering to finish
        renderTask.promise.then(() => {
          this.pageRendering = false;
          /*if (pageNumPending !== null) {
            // New page rendering is pending
            renderPage(pageNumPending);
            pageNumPending = null;
          }*/
        });
      });

      // Update page counters
      //document.getElementById('page_num').textContent = num;
    },
    prevPage() {
      if (this.page <= 1) {
        return;
      }
      this.page--;
      this.renderPage(this.page);
    },
    nextPage() {
      if (this.page >= this.pdfDoc.numPages) {
        return;
      }
      this.page++;
      this.renderPage(this.page);
    }

  }
};
</script>
<style scoped>
.card {
  max-width: 1000px;
  margin: auto;
  box-shadow: 0 0.5em 3em 0.875em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
}

.card.fullscreen {
  max-width: 100vw;
  width: 100vw;
  height: 100vh;
}

h4 {
  margin-bottom: 0 !important;
  margin-top: 10px;
}
.file-preview {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-width: 500px;
  margin: auto;
  z-index: 10000000000;
  display: flex;
  flex-direction: column;
}
.card-content {
  flex-grow: 1;
  max-height: 75vh;
  overflow-y: auto;
}
.fullscreen .card-content {
  max-height: calc(100vh - 48px);
  height: calc(100vh - 48px);
}
.minimized .card-content {
  max-height: 0;
  height: 0;
  padding: 0;
}
</style>
