import Api from '@/core/Api';

export const state = () => ({
  editedBookmark: undefined,
  objects: [],
});

export const actions = {
  async fetchObjects({ commit }) {
    const { documents } = await Api.$get('http://localhost:3332/bookmarks');
    commit('setBookmarks', documents);
  },
  async saveObject({ commit }, bookmark) {
    const payload = await Api.post(`http://localhost:3332/bookmarks/${bookmark._id}`, bookmark);
    commit('updateBookmark', payload.data.document);
  },
  async createObject({ commit }, bookmark) {
    const payload = await Api.put('http://localhost:3332/bookmarks', bookmark);
    commit('addBookmark', payload.document);
  },
  async deleteObject({ commit }, bookmark) {
    const payload = await Api.delete(`http://localhost:3332/bookmarks/${bookmark._id}`);
    if (payload.data.success) {
      commit('deleteBookmark', bookmark);
    }
  },
};

export const mutations = {
  setEditedBookmark(state, bookmark) {
    state.editedBookmark = bookmark;
  },
  addBookmark(state, todo) {
    state.objects.push(todo);
  },
  setBookmarks(state, bookmarks) {
    state.objects = bookmarks;
  },
  updateBookmark(state, bookmark) {
    state.editedBookmark = undefined;
    state.objects = state.objects.map((b) => {
      if (b._id === bookmark._id) {
        return bookmark;
      }
      return b;
    });
  },

  deleteBookmark(state, bookmark) {
    for (let i = 0; i < state.objects.length; i++) {
      if (state.objects[i]._id === bookmark._id) {
        state.objects.splice(i, 1);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
