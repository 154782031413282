<template>
  <div
    :class="`card  ${isMobile ? 'mobile': 'desktop'} ${hasMenu ? 'has-menu' : ''}`"
  >
    <button v-if="isMobile && config.contextMenu && config.contextMenu.length" class="button mobile-menu-button" @click="toggleMobileMenu(element)">
      <i class="mdi mdi-menu" />
    </button>
    <div>
      <div :class="`multiselect-button ${isSelected ? 'selected': ''}`" @click="$emit('toggleSelected', element)">
        <i v-if="isSelected" class="mdi mdi-check-circle" />
        <i v-else class="mdi mdi-radiobox-blank" />
      </div>
      <div class="content-wrapper" @click="click">
        <div v-if="config.templateEditor === 'WYSIWYG' && config.templateComponent !== undefined">
          <component
            :is="config.templateComponent.name || 'CardRecordTemplate'"
            :element="element"
            :panel-id="config._id"
          >
            <span v-for="(f, i) in config.templateComponent.fields" :key="i" :class="f.styles.join(' ')">
              <template v-if="!f.renderComponent">
                {{ element[f.model] }}
              </template>
              <component v-else :is="`render-component-${f.renderComponent}`" :value="element[f.model]" :field="f" />
            </span>
          </component>
        </div>
        <v-runtime-template v-else-if="config.template !== undefined" :template="config.template" />
        <div v-else class="card-content">
          <div class="content">
            <span>
              {{ element.title }}
            </span>
          </div>
        </div>
      </div>
      <slot />
      <div class="favorites-button-wrapper">
        <div v-if="config.hasFavorites" :class="`favorite-button ${isFavorite ? 'selected': ''}`" @click="toggleSelected">
          <i :class="`mdi mdi-star${isFavorite ? '': '-outline'}`" />
        </div>
      </div>
    </div>
    <div class="mobile-menu-slot">
      <slot name="mobile-menu" />
    </div>
  </div>
</template>
<script>
import { isMobile } from 'mobile-device-detect';
import VRuntimeTemplate from 'v-runtime-template';
import Vue from 'vue';
import '@/components/record-templates/render-components/index';

export default {
  components: {
    VRuntimeTemplate,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      default: undefined,
    },
    schema: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isMobile,
      hasFavorites: true,
    };
  },
  computed: {
    isSelected() {
      return this.$parent.selection && this.$parent.selection.includes(this.element);
    },
    isFavorite() {
      return this.$store.state.favorites.favorites[this.config.collection] && this.$store.state.favorites.favorites[this.config.collection].includes(this.element._id);
    },
    hasMenu() {
      return this.config.contextMenu && this.config.contextMenu.length;
    },
  },
  methods: {
    click(e) {
      if (e.target.closest('.prevent-click') === null) {
        const clickAction = this.config.clickAction || 'edit';
        if (clickAction !== 'none') {
          this.$emit(clickAction, this.element);
        }
      }
    },
    toggleSelected() {
      if (this.isFavorite) {
        this.$store.dispatch('favorites/removeFromFavorites', { collection: this.config.collection, id: this.element._id });
      } else {
        this.$store.dispatch('favorites/addToFavorites', { collection: this.config.collection, id: this.element._id });
      }
    },
    toggleMobileMenu(element) {
      if (this.$parent.contextMenuElement !== element) {
        this.$parent.contextMenuElement = element;
      } else {
        this.$parent.contextMenuElement = undefined;
      }
    },
  },
};
</script>
<style scoped>
.card {
  position: relative;
  overflow: visible;
}
.card.mobile {
  position: static;
}
.menu {
  position: absolute;
  top: 0;
  right: 0;
}

.card.mobile.has-menu {
  padding-right: 74px;
}
.mobile-menu-button {
  width: 50px;
  height: 100%;
  position: absolute;
  right: 0;
  border-radius: 0;
}
.card.focused .card-header-title {
  color: white;
}

.card.focused .card-content {
  background: #7957d561;
}

.card.dirty:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-left: 6px solid #ffdd57;
}

.card-content {
  transition: background 0.2s, margin-top 0.2s, margin-bottom 0.2s, box-shadow 0.2s,;
}
.mobile .card-content {
  padding-right: 74px;
}

.multiselect-button {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0px;
  transition: left 0.1s, width 0.1s, background 0.3s;
  border: 1px;
  width: 0px;
  overflow: hidden;
  padding-top: 22px;
  padding-left: 12px;
  font-size: 125%;
  cursor: pointer;
}
.multiselect-button.selected {
  background: var(--primary-color);
  color: white;
}
.favorite-button {
  position: absolute;
  top: 0;
  height: 100%;
  right: 0px;
  transition: right 0.1s, width 0.1s, background 0.3s;
  border: 1px;
  width: 0px;
  overflow: hidden;
  padding-top: 22px;
  padding-left: 12px;
  font-size: 125%;
  cursor: pointer;
}
.favorite-button.selected {
  background: #ffdd57;
  color: #363636;
}
.content-wrapper {
  background: white;
  z-index: 20;
}
.mobile .favorites-button-wrapper {
  position: relative;
  z-index: 0;
}
.mobile .favorite-button {
  background: white;
  right: 0px;
  top: -73px;
  height: 71px;
  width: 40px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
</style>
<style>
  .ctrl-key-down .multiselect-button {
    background: white;
    left: -40px;
    width: 40px;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  }
  .ctrl-key-down .favorite-button {
    background: white;
    right: -40px;
    width: 40px;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  }

  .card-content {
    transition: background 0.2s, margin-top 0.2s, margin-bottom 0.2s, box-shadow 0.2s, border-bottom 0.2s;
    border-bottom: 1px solid #ddd;
  }

  .not-touch-device .card-content:not(.static):hover {
    background: #CCCCCC22;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.2), 0 0 0 1px rgba(10, 10, 10, 0.1);
    border-bottom: 1px solid #bbb;
  }

</style>
