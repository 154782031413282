export const state = () => ({
  online: undefined,
  whenOnlineActions: [],
});

export const mutations = {
  setOnline(state, online) {
    state.online = online;
    for (let i = 0; i < state.whenOnlineActions.length; i++) {
      state.whenOnlineActions[i]();
    }
    state.whenOnlineActions = [];
  },
  whenOnline(state, { doAction, elseAction }) {
    // TODO save store actions or mutations, not callback, to be able to save them to localstorage and inspect them
    if (state.online) {
      doAction();
    } else {
      state.whenOnlineActions.push(doAction);
      elseAction();
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
