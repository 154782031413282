import Vue from 'vue';

import RelationList from '@/components/RelationList';
import AddToRelationModal from '@/components/modals/AddToRelationModal';
import SessionsManager from '@/components/SessionsManager';
import EditButton from '@/components/EditButton';
import FilePreview from '@/components/FilePreview';
import DocumentTable from '@/components/blocks/components/DocumentTable';
import FavoritesList from '@/components/blocks/components/FavoritesList';
import WeekPicker from '@/components/ui/WeekPicker';
import DatePicker from '@/components/ui/DatePicker';

Vue.component('RelationList', RelationList);
Vue.component('AddToRelationModal', AddToRelationModal);
Vue.component('SessionsManager', SessionsManager);
Vue.component('EditButton', EditButton);
Vue.component('FilePreview', FilePreview);
Vue.component('DocumentTable', DocumentTable);
Vue.component('FavoritesList', FavoritesList);
Vue.component('WeekPicker', WeekPicker);
Vue.component('DatePicker', DatePicker);
