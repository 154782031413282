import { io } from 'socket.io-client';
const socket = {
  connect(token, commit) {
    setTimeout(() => {
      if (!this.io || this.io.disconnected) {
        console.log('socket error');
      }
    }, 15000);
    const url_without_protocol = process.env.VUE_APP_BASE_URL.split('//')[1].split(':')[0];
    let url = `${process.env.VUE_APP_CONNECTION_SECURE ? 'https' : 'http'}://${url_without_protocol}:${process.env.VUE_APP_SOCKET_PORT || 8080}`;
    console.log('connect socket to ', url);
    this.io = io(url, {
      query: { token },
    });
    console.log(token, 'connected to websocket');
    this.io.on('pong', () => {
      alert('pong');
    });
    window.$io = this.io;
  },
  disconnect() {
    try {
      this.io.disconnect();
    } catch (e) {
      console.error(e);
    }
  },
};
window.$socket = socket;
export default socket;
