<template>
  <span>
    {{ value ? value.length || 0 : 0 }}
  </span>
</template>
<script>
export default {
  name: 'RenderComponentArraycount',
  simpleName: 'arraycount',
  needField: true,
  props: {
    value: {
      type: Array,
      default: undefined,
    },
  },
};
</script>
