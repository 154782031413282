import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const RouterLink = Vue.component('RouterLink');
Vue.component('nuxt-link', RouterLink);

const RouterView = Vue.component('RouterView');
Vue.component('nuxt', RouterView);
const login = 'login';
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "index" */ '../pages/index.vue'),
  }, {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../pages/login.vue'),
  }, {
    path: '/panel/:id',
    name: 'PanelId',
    component: () => import(/* webpackChunkName: "PanelId" */ '../pages/panel/_id/index.vue'),
  }, {
    path: '/dashboard/:id',
    name: 'DashboardId',
    component: () => import(/* webpackChunkName: "DashboardId" */ '../pages/dashboard/_id.vue'),
  }, {
    path: '/page/:id',
    name: 'PageId',
    component: () => import(/* webpackChunkName: "PageId" */ '../pages/page/_id.vue'),
  }, {
    path: '/panel/:id/:documentId',
    name: 'PanelIdDocumentId',
    component: () => import(/* webpackChunkName: "PanelId" */ '../pages/panel/_id/_documentId.vue'),
  }, {
    path: '/documentation',
    name: 'Documentation',
    component: () => import(/* webpackChunkName: "Documentation" */ '../pages/documentation/index.vue'),
  }, {
    path: '/documentation/reference/:id',
    name: 'DocumentationReferencePage',
    component: () => import(/* webpackChunkName: "DocumentationReferencePage" */ '../pages/documentation/reference/_id.vue'),
  }, {
    path: '/documentation/:id',
    name: 'DocumentationPage',
    component: () => import(/* webpackChunkName: "DocumentationPage" */ '../pages/documentation/_page.vue'),
  }, {
    path: '/about',
    name: 'AboutPage',
    component: () => import(/* webpackChunkName: "AboutPage" */ '../pages/about.vue'),
  },
];

const router = new VueRouter({
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.name !== 'Login' && store.state.login.user === undefined) {
//     next({ name: 'Login' });
//   } else if (to.name === 'Login' && store.state.login.user !== undefined) {
//     next({ name: 'Home' });
//   } else {
//     next();
//   }
// })

export default router;
