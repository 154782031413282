<template>
  <router-link
    :to="to + `?workgroup=${currentWorkgroup}`"
    exact-active-class="is-active"
  >
    <slot/>
  </router-link>
</template>
<script>
import { mapState } from 'vuex';
// const RouterLink = Vue.component('router-link');
export default {
  props: {
    to: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      currentWorkgroup: (state) => state.login.currentWorkgroup,
    }),
  },
};
</script>
