import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import axiosPlugin from './plugins/axios';

import abstractElements from './abstractElements';
import abstractElementsPaginated from './abstractElementsPaginated';
import bookmarks from './bookmarks';
import config from './config';
import customFilters from './customFilters';
import errors from './errors';
import favorites from './favorites';
import fileViewer from './fileViewer';
import listPagination from './listPagination';
import experimentalFeatures from './experimentalFeatures';
import login from './login';
import offlineHandler from './offlineHandler';
import panels from './panels';
import documentation from './documentation';
import statusMessages from './statusMessages';
import persistantStorage from './persistantStorage';
import persistantStorage2 from './persistantStorage2';
import currentPanel from './currentPanel';

Vue.use(Vuex);

const modules = {
  abstractElements,
  abstractElementsPaginated,
  bookmarks,
  config,
  customFilters,
  errors,
  favorites,
  fileViewer,
  listPagination,
  experimentalFeatures,
  login,
  offlineHandler,
  panels,
  documentation,
  statusMessages,
  persistantStorage,
  persistantStorage2,
  currentPanel,
};

for (const m in modules) {
  modules[m].$axios = axios;
}

export default new Vuex.Store({
  modules,
  plugins: [axiosPlugin],
});
